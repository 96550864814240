import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CreditCountPipe } from 'src/app/pipe/credit-count.pipe';
import { CampaignService } from 'src/app/services/campaign/campaign.service';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { TemplateService } from 'src/app/services/templates/template.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { CAMPAIGN_NAME_HINT, CAMPAIGN_NAME_REGEX, PLAIN_TXT_REGEX } from 'src/environments/environment';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';

@Component({
  selector: 'app-quick-campaign',
  templateUrl: './quick-campaign.component.html',
  styleUrls: ['./quick-campaign.component.scss']
})
export class QuickCampaignComponent implements OnInit {

  quickCampaignForm = this.fb.group({
    senderId: [null, [Validators.required]],
    entityId: ['', Validators.required],
    loggedInUserName: this.storageService.username,
    campaignName: [null, [Validators.required, Validators.pattern(CAMPAIGN_NAME_REGEX)]],
    mobileNumber: ['', Validators.required],
    serviceType: 'trans',
    serviceSubType: 'service-implicit',
    dltTemplateId: ['', [Validators.required]],
    isShortUrlSelected: "N",
    msgPart: "single",
    msgText: ['', [Validators.required]],
    msgType: "plain",
    perMsgCredit: "",
    shortUrlName: [null],
    templateName: [''],
  });

  messageType: string = 'trans';
  note: string = CAMPAIGN_NAME_HINT;
  templateSelected: any;
  auto: any;
  listOfSenderIds: any;
  listOfTemplates: any;
  listOfShortUrl: any;
  senderIdSelected: any;
  totalMobileNumbers: any;
  isShortUrlSelected: string = 'N';
  messageText: string = '';
  listOfURLSelected: string[] = [];

  isVisible: boolean = false;
  isOkLoading: boolean = false;
  msgTextLength:number=0;

  constructor(
    private fb: FormBuilder,
    private storageService: StorageService,
    private templateService: TemplateService,
    private campaignService: CampaignService,
    private reportService: ReportService,
    private utilService: UtilsService,
    private creditCountPipe: CreditCountPipe
  ) { }

  ngOnInit(): void {
    this.getSenderIdsByMsgType();
    this.getAllShortUrl();
    this.genCampaignName();
  }

  lengthWithoutNewlines(str: string) {
    return str.replace(/\r/g, '').replace(/\n/g, '').length;
  }
  msgLength(){
    var sectionToCheck = this.quickCampaignForm.get('msgText')?.value;
   var allFoundCharacters = sectionToCheck.match(/[\[\]\{\}\\|₹\^€\~]/g);
   var splCharCount = (allFoundCharacters) ? allFoundCharacters.length : 0;
   var charCount = this.lengthWithoutNewlines(this.quickCampaignForm.get('msgText')?.value) + parseInt(splCharCount);
   this.msgTextLength = charCount;
 }


  mobileNumberInputChange(event: any) {
    this.totalMobileNumbers = this.campaignService.getMobileNumberCount(event);
  }

  onItemSelect(shortname: any, field: any) {
    const cursorPos = field.selectionStart;
    const msgText = this.quickCampaignForm.value.msgText || ''; 
    const textBefore = msgText.substring(0, cursorPos); 
    const textAfter = msgText.substring(cursorPos);  
    const shortObj = this.listOfShortUrl.find((p: any) => p.name === shortname);
    if (shortObj) {
        const shortUrl = `${shortObj.hostName}/xxxxxx`;
        const newMsgText = textBefore + shortUrl + textAfter;
        this.quickCampaignForm.patchValue({
            msgText: newMsgText
        });
        setTimeout(() => {
            field.focus();
            field.setSelectionRange(cursorPos + shortUrl.length, cursorPos + shortUrl.length);
        }, 0);
    }
}
onItemDeSelect(event: any) {
  this.removeShortUrlInMsgText(event);
}


onShortUrlSelect(event: string[], field: any) {
  if (this.listOfURLSelected.length < event.length) {
      const newItem = event.filter((x: any) => !this.listOfURLSelected.includes(x))[0];
      console.log("newItem", newItem);
      this.listOfURLSelected = event;
      this.onItemSelect(newItem, field);
  } else {
      const removedItem = this.listOfURLSelected.filter((x: any) => !event.includes(x))[0];
      console.log("removedItem", removedItem);
      this.listOfURLSelected = this.listOfURLSelected.filter((x: any) => x !== removedItem);
      this.removeShortUrlInMsgText(removedItem);
  }
}



removeShortUrlInMsgText(value: any) {
  const msgText = this.quickCampaignForm.value.msgText; 
  const unSelectData = value;
  const shortObj = this.listOfShortUrl.find((p: any) => p.name === unSelectData);
  if (!shortObj) {
    console.error("Short URL object not found");
    return;
  }
  const findingText = `${shortObj.hostName}/xxxxxx`;
  let updatedMsgText = msgText;
  const index = updatedMsgText.indexOf(findingText);
  
  if (index !== -1) {
    updatedMsgText = updatedMsgText.slice(0, index) + updatedMsgText.slice(index + findingText.length);
    this.quickCampaignForm.patchValue({
      msgText: updatedMsgText
    });
    this.listOfURLSelected = this.listOfURLSelected.filter((item: any) => item !== unSelectData);
    console.log("URL removed successfully.");
  } else {
    console.error("URL not found in the message text.");
  }
}


  submitForm() {
    if (this.totalMobileNumbers > 10000) {
      this.utilService.showErrorMsg('You cannot submit more than 10,000 mobile numbers.');
      return; 
    }
    if (this.quickCampaignForm.valid) {
      let rawData = this.quickCampaignForm.getRawValue();
      if (this.quickCampaignForm.valid) {
        if (this.campaignService.validateCampaignForm(rawData)) {
          // this.submitQuickCampaign();
          this.isVisible = true;
        }
      }
    } else {
      Object.values(this.quickCampaignForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  submitQuickCampaign() {
    let params = this.quickCampaignForm.getRawValue();
    params.perMsgCredit = this.creditCountPipe.transform(params.msgText);
    params.contentTemplateId = params.templateName.templateId;
    delete params.templateName;
    params.mobileNumber = params.mobileNumber.replaceAll('\n', '\r\n');
    if (params.serviceType !== "service") {
      delete params.serviceSubType;
    }

    this.campaignService.sendQuickSMS(params).subscribe(data => {
      this.resetForm();
      this.isVisible = false;
      this.isOkLoading = false;
      if (data.result === "Success") {
        this.utilService.showSuccessMsg(data.message);
      } else {
        this.utilService.showErrorMsg(data.message);
      }
    }, err => {
      this.utilService.showErrorMsg('Something Went Wrong');
    });
  }


  resetForm() {
    this.totalMobileNumbers = 0;
    this.quickCampaignForm.reset()
    this.quickCampaignForm.patchValue({
      serviceType: 'trans',
      serviceSubType: 'service-implicit',
      isShortUrlSelected: "N",
      msgPart: "single",
      msgType: "plain",
      msgLength:
      this.msgTextLength = this.quickCampaignForm.get('msgText')?.value,
      loggedInUserName: this.storageService.username,
    });
    this.genCampaignName();
  }

  genCampaignName() {
    this.quickCampaignForm.patchValue({
      campaignName: this.campaignService.genCampaignName()
    })
  }

  getAllShortUrl() {
    this.reportService.listAllShortUrl().subscribe(data => {
      this.listOfShortUrl = data.data.shortUrlList;
    })
  }

  getSenderIdsByMsgType(type: string = this.messageType, subType: string = 'service-implicit') {
    let sub = type === 'others' ? subType : '';
    if(type === 'others'){
      console.log(sub)
    }
    this.templateService.getSenderIdByMsgType(type, sub).subscribe(data => {
      this.quickCampaignForm.get('senderId')?.reset?.();
      this.listOfTemplates = [];
      this.listOfSenderIds = data.data.senderIdList;
    })
  }

  getTemplates(type: string, senderId: any) {
    let params = { "loggedInUserName": this.storageService.username, "messageSubType": (this.messageType=='others')?this.quickCampaignForm.get('serviceSubType')?.value:'', "messageType": (this.messageType=='others')?'service':this.messageType, "senderId": senderId };
    this.campaignService.getTemplatesBySenderId(params).subscribe(data => {
      this.listOfTemplates = data.data.contentTemplateList;
    })
  }


  handleOk() {
    this.isOkLoading = true;
    this.submitQuickCampaign();
  }

  handleCancel() {
    this.isVisible = false;
  }
}
