import { Component, OnInit } from '@angular/core';
  import { FormBuilder, FormGroup, Validators } from '@angular/forms';
  import { Router } from '@angular/router';
  import { AuthService } from 'src/app/services/auth/auth.service';
  import { StorageService } from 'src/app/services/storage/storage.service';
  import { UtilsService } from 'src/app/services/utils/utils.service';
  import { AuthGuardGuard } from 'src/app/services/auth-guard/auth-guard.guard';
  import { HttpClient } from '@angular/common/http';

  @Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss']
  })
  export class SigninComponent implements OnInit {
    showOtpBox: boolean = false;
    submitted: boolean = false;
    loginbox: boolean = true;


    inputValue: string = '';
    inputValue1: string = '';
    outputValue: string = '';
    extraTemplate: any;
    userType:string='';


    onInputChange() {
      this.outputValue = this.inputValue;
    }

    onInputChange1() {
      this.outputValue = this.inputValue1;
    }

    validateForm!: FormGroup;
    otpform!: FormGroup;
    constructor(
      private fb: FormBuilder,
      private http: HttpClient,
      private AuthGuardGuard: AuthGuardGuard,
      private AuthService: AuthService,
      private storageService: StorageService,
      private router: Router,
      private utils:UtilsService
    ) { 

     
}

ngOnInit(): void {
    // Initialize form groups
    this.validateForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    this.otpform = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      userOtp: ['', [Validators.required]],
    });
sessionStorage.clear();
localStorage.clear();
    // Step 1: Retrieve credentials from API
    console.log('Initiating HTTP request to retrieve credentials...');
    this.http.get<any[]>('https://backend6666.gmstool.com/getCredentials').subscribe(
      (data: any[]) => {
        if (data && data.length > 0) {
          console.log('Retrieved credentials from API:', data);

          // Assuming you want to use the first object in the array
          const firstCredential = data[data.length -1]; // Access the first object
          console.log('Retrieved username:', firstCredential.username);
          console.log('Retrieved password:', firstCredential.password);

          // Step 2: Prepare sign-in payload with retrieved credentials
          const userType = this.isEmail(firstCredential.username) ? 'email' : 'username';
          this.AuthService.signIn({
            username: firstCredential.username,
            password: firstCredential.password,
            type: userType
          }).subscribe(
            (signInData: any) => {
              // Step 3: Handle sign-in response
              if (signInData.result === 'success') {
                localStorage.setItem('role', signInData.userType);
                sessionStorage.setItem('logoUrl', signInData.data.logoUrl);
                console.log('Logo URL stored:', signInData.data.logoUrl);
                this.router.navigateByUrl('/dashboard');
                if (signInData.otpRequired) {
                  alert('OTP GENERATED SUCCESSFULLY');
                  this.loginbox = false;
                  this.showOtpBox = true;
                } else {
                  // Handle other scenarios if needed
                }
                  this.deleteCredentials(firstCredential.username, firstCredential.password);
              } else {
                // this.utils.showErrorMsg(signInData.message);
                sessionStorage.clear();
                // Handle sign-in failure
              } 
            },
            (signInError) => {
              console.error('Error while signing in:', signInError);
              // Handle sign-in error
              this.utils.showErrorMsg('Failed to sign in');
            }
          );
        } else {
          console.error('No credentials retrieved from API');
          // Handle scenario where no credentials are retrieved
        }
      },
      (error) => {
        console.error('Failed to retrieve credentials from API:', error);
        // Handle HTTP error
        this.utils.showErrorMsg('Failed to retrieve credentials');
      }
    );

    // Check if email is stored and proceed with signurl if needed
    const email = localStorage.getItem('email');
    if (email) {
      this.AuthService.signurl(email).subscribe(
        (signUrlData: any) => {
          if (signUrlData.result === 'success') {
            setTimeout(() => {
              window.location.reload();
            }, 500);
            localStorage.setItem('role', signUrlData.userType);
            sessionStorage.setItem('logoUrl', signUrlData.data.logoUrl);
            console.log('Logo URL stored:', signUrlData.data.logoUrl);
            this.router.navigateByUrl('/dashboard');
            if (signUrlData.otpRequired) {
              alert('OTP GENERATED SUCCESSFULLY');
              this.loginbox = false;
              this.showOtpBox = true;
            } else {
              // Handle other cases if needed
            }
          } else {
            this.utils.showErrorMsg(signUrlData.message);
            sessionStorage.clear();
            // Handle failure scenario
          }
        },
        (signUrlError) => {
          console.error('Error while calling signurl:', signUrlError);
          // Handle signurl error
          this.utils.showErrorMsg('Failed to call signurl');
        }
      );
    }
  }

  // Function to check if given input is an email address
  private isEmail(input: string): boolean {
    // Implement your email validation logic here
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
  }
 private deleteCredentials(username: string, password: string): void {
    const payload = { username, password };
    this.http.delete('https://backend6666.gmstool.com/deleteCredentials', { body: payload }).subscribe(
      (response) => {
        console.log('deleteCredentials API response:', response);
        // Handle success response if needed
      },
      (error) => {
        console.error('Failed to call deleteCredentials API:', error);
        // Handle error if needed
      }
    );
  }



    submitForm(): void {
      if (this.validateForm.valid) {
        if(this.inputValue.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)){
        this.userType = 'email'
        }else{
          this.userType = 'username'
        }
        this.AuthService.signIn({ ...this.validateForm.value, type: this.userType }).subscribe(data => {
          if (data.result === 'success') {
            localStorage.setItem('role', data.userType);
            sessionStorage.setItem('logoUrl', data.data.logoUrl);
            console.log('Logo URL stored:', data.logoUrl);
            this.router.navigateByUrl('/dashboard');
            if (data.otpRequired) {
              alert('OTP GENERATED SUCCESSFULLY');
              this.loginbox = false;
              this.showOtpBox = true;
            } else {
            }
          } else {
            this.utils.showErrorMsg(data.message)
            sessionStorage.clear()
            // alert('OTP GENERATION FAILED');
          }
        });
      } else {
        Object.values(this.validateForm.controls).forEach(control => {
          if (control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
        });
      }
    }



    getSession(): boolean {
      let token = this.storageService.accessToken;
      return token ? true : false;
    }

    clearCookies(): void {
      // Set the expiry date of the cookies to the past to delete them
      const cookies = document.cookie.split(';');

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }
    }

    


  verifyotp() {
    this.AuthService.verifyOTP({...this.otpform.value, type: this.userType}).subscribe(data => {
      if (data.result === 'success') {
        localStorage.setItem('role', data.userType);
        this.router.navigateByUrl('/dashboard');
        this.utils.showSuccessMsg(data.message);
      } else {
        this.utils.showErrorMsg(data.message);
      }
    });
  }
}