import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ColourService {
  private apiUrl = 'http://localhost:5002/api/color-config';

  constructor(private http: HttpClient) { }

  // Fetch color configuration
  getColorConfig(): Observable<{ dashboardGradient: string }> {
    return this.http.get<{ dashboardGradient: string }>(this.apiUrl);
  }
}