// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const APP_NAME = 'webtool';

export const DOMAIN = 'https://backend66.gmstool.com';
// export const DOMAIN = 'https://backend44.gmstool.com/';
// export const DOMAIN = 'https://uatbackend1.gmstool.com';
// export const DOMAIN = 'https://backend1.hrms4all.com';
// export const DOMAIN = 'https://backend1.ncrvolcano.in';s
export const BASE_URL = `${DOMAIN}/file-uploader`;

export const NUMERIC_REGEX = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
export const SENDER_ID_REGEX = /^(?=.*[a-zA-Z0-9])(?!^\d+$)[a-ziA-Z0-9]{6,6}$/;
export const NUMERIC_6_REGEX = /^[0-9]+$/;
export const PLAIN_TXT_REGEX = /^[-;'\"<>?A-Za-z0-9_…~!:\/.@\\|\\,#\\$%\\^&\\*\\(\\)\n\s +=\\\\{}\\[\]]*$/;
// export const PLAIN_TXT_REGEX = ("^[A-Za-z0-9_~\\-!:\\/.@\\|\\,#\\$%\\^&\\*\\(\\)\n\s\r +=\\\\{}\\'\"<>?]*$");
export const NUMBER_REGEX = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
export const SAMPLE_DYNAMIC_DOWNLOAD = `${DOMAIN}/files/Dynamic_campaign_sample_file.xlsx`
export const CAMPAIGN_NAME_REGEX = '[a-zA-Z0-9_-]+';
export const CAMPAIGN_NAME_HINT = "Special characters are not allowed in campaign name like SPACE , [ ] @ $ % & ^ * ~ { } | \ ; : " + '"' + " ' <> . ? / ~ ` ";

export const environment = {
  production: false,

  loginUrl: `${BASE_URL}/reseller/loginUserNameEmail`,
  Url: `${BASE_URL}/reseller/loginUserByEmailWeb`,
  verifyOTP: `${BASE_URL}/reseller/verfityOtpLoginUserNameEmail`,

  // Dashboard
  dashboardCards: `${BASE_URL}/mis/dashboard`,
  summaryReport: `${BASE_URL}/reportService/summaryReport`,
  senderIDReport: `${BASE_URL}/reportService/senderIdWiseReport`,
  detailedReport: `${BASE_URL}/reportService/detailedReport`,
  getAllCampaignName:`${BASE_URL}/campaignService/getAllCampaignName`,
  getHourlySummaryReport: `${BASE_URL}/reportService/getHourlySummaryReport`,
  telcoReport: `${BASE_URL}/reportService/detailedReport`,

  // Donload Report
  campaignReport: `${BASE_URL}/reportService/campaignReport`,
  viewGeneratedReport: `${BASE_URL}/mis/viewGeneratedReports`,
  generateReport: `${BASE_URL}/mis/generateReport`,

  // Credit History
  creditHistory: `${BASE_URL}/creditService/getCreditHistory`,
  allSenderIdList: `${BASE_URL}/senderIdService/viewAllSenderIdList`,

  // Content Template
  viewAllContentTemplates: `${BASE_URL}/contentTemplateService/viewAllContentTemplateList`,
  getSenderIds: `${BASE_URL}/senderIdService/viewSenderIdListByMessageType`,
  saveContentTemplate: `${BASE_URL}/contentTemplateService/saveContentTemplate`,
  uploadDltDataFile: `${BASE_URL}/uploadDltDataFile`,
  uploadCustomFile: `${BASE_URL}/uploadCustomDltDataFile`,
  addSenderId: `${DOMAIN}/file-uploader/senderIdService/addSenderId`,

  // Phonebook / Blacklist
  // getAllGroups : `${BASE_URL}/groupService/getAllGroupsList`,
  getAllPhoneBookGroup: `${DOMAIN}/file-uploader/groupService/getAllGroupsList`,
  addPhoneBookGroup: `${DOMAIN}/file-uploader/groupService/addGroup`,
  deletePhoneBookGroup: `${DOMAIN}/file-uploader/groupService/deleteGroup`,
  listContacts: `${DOMAIN}/file-uploader/groupNumberDetailsService/getAllNumbersInGroup`,
  searchGroupNumber: `${DOMAIN}/file-uploader/groupNumberDetailsService/searchNumber`,
  removeNumberFromGroup: `${DOMAIN}/file-uploader/groupNumberDetailsService/removeNumberFromGroup`,
  addNewContacts: `${DOMAIN}/file-uploader/groupNumberDetailsService/addNumberToGroup`,
  uploadNumberInUserGroup: `${BASE_URL}/groupService/uploadNumberInUserGroup`,
  getAllBlacklistNumbersForUser: `${BASE_URL}/userBlackListService/getAllBlacklistNumbersForUser`,
  removeNumberFromUserBlackList: `${DOMAIN}/file-uploader/userBlackListService/removeNumberFromUserBlackList`,
  addNumberToBlackList: `${BASE_URL}/userBlackListService/addNumberInUserBlackList`,
  uploadBlackList: `${BASE_URL}/userBlackListService/uploadUserBlackListNumberFile`,

  deleteSenderId: `${BASE_URL}/senderIdService/deleteSenderId`,
  deleteTemplate: `${DOMAIN}/file-uploader/contentTemplateService/deleteContentTemplate`,
  searchContentTemplate: `${BASE_URL}/contentTemplateService/searchContentTemplate`,
  operatorTemplateId: `${BASE_URL}/contentTemplateService/searchContentTemplate`,
  templateTitle: `${BASE_URL}/contentTemplateService/searchContentTemplate`,
  // Webtool
  detailedAnalyticsReport: `${DOMAIN}/file-uploader/reportService/detailedAnalyticsReport`,
  activeHostName: `${DOMAIN}/file-uploader/shortUrlService/getAllActiveAndApprovedHostNameForUser`,
  addShortUrl: `${DOMAIN}/file-uploader/shortUrlService/addShortUrl`,
  viewAllShortUrl: `${DOMAIN}/file-uploader/shortUrlService/viewAllActiveShortUrlForUser`,
  deleteShortUrl: `${DOMAIN}/file-uploader/shortUrlService/deleteShortUrl`,
  listAlApprovedDomains: `${DOMAIN}/file-uploader/shortUrlService/viewAllActiveHostNameForUser`,
  deleteHostName: `${BASE_URL}/shortUrlService/deleteHostName`,
  addDomainName: `${BASE_URL}/shortUrlService/addHostName`,
  getCampaignReport: `${DOMAIN}/file-uploader/campaignService/viewConsolidateScheduledCampaignForUser`,
  deletescheduleCampaignReport: `${DOMAIN}/file-uploader/campaignService/deleteConsolidateScheduledCampaignForUser`,
  // Campaign
  viewContentTemplate: `${DOMAIN}/file-uploader/contentTemplateService/viewAllContentTemplateListByMessageType`,
  sendQuickSMS: `${DOMAIN}/file-uploader//sendSMSService/sendQuickSMS`,
  uploadFile: `${DOMAIN}/file-uploader/uploadFile`,
  uploadCampaign: `${DOMAIN}/file-uploader/scheduleFile`,
  uploadDynamicFile: `${DOMAIN}/file-uploader/dynamicSMSService/uploadDynamicMessageFile`,
  dynamicPreview: `${DOMAIN}/file-uploader/dynamicSMSService/getPreview`,
  dynamicCampaign: `${DOMAIN}/file-uploader/dynamicSMSService/sendDynamicSMS`,
  groupCampaign: `${DOMAIN}/file-uploader/groupSMSService/sendGroupSMS`,

  //Reportu
  clickerAnalytics : `${DOMAIN}/file-uploader/reportService/clickerAnalytics`,
  clickerreportAnalytii: `${DOMAIN}/file-uploader/reportService/userClickerSummaryReport`,
  // clickerreportAnalytii: `${DOMAIN}/file-uploader/reportService/detailedAnalyticsReport`,
  profile: `${DOMAIN}/file-uploader/userProfile/userProfileDetails`,
  updatedPassword: `${DOMAIN}/file-uploader/userProfile/updatedPassword`,
  url: `${BASE_URL}`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
