import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { StorageService } from 'src/app/services/storage/storage.service';
import { TemplateService } from 'src/app/services/templates/template.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';



@Component({
  selector: 'app-upload-template',
  templateUrl: './upload-template.component.html',
  styleUrls: ['./upload-template.component.scss']
})
export class UploadTemplateComponent implements OnInit {

  templateFileType: string = 'HEADER_FILE';
  myData: any;
  file_name: any;
  file_err: boolean =true;
  file_err_msg: any = '';

  uploadshow:boolean=true;
  tempshow:boolean = false;


  @ViewChild('fileInput')
  myInputVariable!: ElementRef;
  fileObjResponse = false;

  coloumnHeaders:any = [];
  uploadContentTemplate = this.fb.group({
    fileType: ['HEADER_FILE'],
    description: [''],
    fileExtension: ['csv'],
    fileName: [''],

    operatorId: [''],
    customColumnMapping: this.fb.group({
      TEMPLATE_ID: [''],
      TEMPLATE_NAME: [''],
      TYPE: [''],
      HEADER: [''],
      CATEGORY: [''],
      STATUS: [''],
      TEMPLATE_MESSAGE: ['']

    })
  });
  tosterService: any;
  utilService: any;
  customColumnMapping: any;


  constructor(
    private templateService: TemplateService,
    private storageService: StorageService,
    private utils: UtilsService,
    private fb: FormBuilder,
  ) {

  }

  ngOnInit(): void {
  }
  // downloadFile() {
  //   const fileURL = 'assets/Templale_upload_sample_file.xlsx';
  //   const a = document.createElement('a');
  //   a.href = fileURL;
  //   a.download = 'Templale_upload_sample_file.xlsx';
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  // }
  getControlValidationClass(controlName: string): string {
    const control = this.uploadContentTemplate.get(controlName);

    if (!control) {
      return '';
    }

    if (control.invalid && (control.dirty || control.touched)) {
      return 'has-error';
    } else if (control.valid && (control.dirty || control.touched)) {
      return 'has-success';
    }

    return '';
  }

  resetAddBlacklistForm(){
    this.uploadContentTemplate.patchValue({

      description: [''],

      fileName: [''],

      operatorId: [''],
      customColumnMapping: this.fb.group({
        TEMPLATE_ID: [''],
        TEMPLATE_NAME: [''],
        TYPE: [''],
        HEADER: [''],
        CATEGORY: [''],
        STATUS: [''],
        TEMPLATE_MESSAGE: [''],

      })

    });
  }


  onUploadSubmit() {

    let formArrayData = this.uploadContentTemplate.getRawValue();
    console.log(this.uploadContentTemplate.value);
    let dttt:any = {}
    if(formArrayData.operatorId === 'customOperator') {
      dttt = {
        "userName": this.storageService.username,
        "fileType": formArrayData.fileType,
        "fileExtension": formArrayData.fileExtension,
        "operatorId": formArrayData.operatorId,
        "fileName": formArrayData.fileName,
        "customColumnMapping": {
          "TEMPLATE ID": formArrayData.customColumnMapping.TEMPLATE_ID,
          "TEMPLATE NAME" : formArrayData.customColumnMapping.TEMPLATE_NAME,
          "TYPE": formArrayData.customColumnMapping.TYPE,
          "HEADER" : formArrayData.customColumnMapping.HEADER,
          "CATEGORY" : formArrayData.customColumnMapping.CATEGORY,
          "STATUS": formArrayData.customColumnMapping.STATUS,
          "TEMPLATE MESSAGE": formArrayData.customColumnMapping.TEMPLATE_MESSAGE
        },
        "entityId": formArrayData.description
      }
    } else {
      dttt = {
        "userName": this.storageService.username,
        "fileType": formArrayData.fileType,
        "fileExtension": formArrayData.fileExtension,
        "operatorId": formArrayData.operatorId,
        "fileName": formArrayData.fileName,
        "entityId": formArrayData.description,
        "customColumnMapping": {no:'norecord'}
      }
    }


let files = this.file?.srcElement?.files[0];
if (!files) {
  this.utils.showErrorMsg("Please fill All Field");
    return;
}


    // let formData: FormData = new FormData();
    // formData.append('file', files);
    // formData.append("userName", this.loggedInUser);
    // formData.append("fileType", formArrayData.fileType);
    // formData.append("entityId", formArrayData.description);
    // formData.append("operatorId", formArrayData.senderId);


    this.templateService.uploadTemplate(dttt).subscribe(data => {
      // this.addTemplateForm.reset()
      if (data.result === "Failure") {
        this.utils.showErrorMsg("Something Went Wrong");
      } else {
        this.utils.showSuccessMsg(data.message)
        this.uploadContentTemplate.patchValue({
          fileType: 'HEADER_FILE',
          description: '',
          fileTypes: 'csv',
          senderId: '',
          entityId: '',
          operatorId: ''
        });
        this.file_err = false;
        this.fileObjResponse = false;
        // this.addReset();
      }
    }, err => {
      this.utils.showErrorMsg("Something Went Wrong");
    })
    this.uploadContentTemplate.reset()
    this.customColumnMapping.reset()
    this.uploadReset();
    window.location.reload()


  }

  radioChange(event: any) {
    //this.fileObjResponse = false;
    this.file_err = false;
  }

  uploadBulk() {
    // console.log(this.file);

    // if(this.file === undefined) {
    //   this.fileObjResponse = false;
    //   this.file_err = true
    //   this.file_err_msg = "Please upload ."+this.uploadContentTemplate.value.fileExtension+" Template File Type option selection. Please check."
    //   return;
    // }
    let files = this.file.srcElement.files[0]
    var ext = files.name.split('.');
    if (!files) {
      return
    }
    let uploadedFiletype = files.name.split('.').pop();
    if ((uploadedFiletype == "csv") || (uploadedFiletype == "zip")
      || (uploadedFiletype == "xlsx")) {
    } else {
      this.utils.showSuccessMsg("Something Went Wrong");
      return
    }
    let formData: FormData = new FormData();
    formData.append('file', files);
    formData.append("userName", this.storageService.username);
    formData.append("fileType", ext[ext.length - 1]);
    formData.append("operatorId", this.uploadContentTemplate.value.operatorId);

    this.templateService.uploadCustomDltDataaFile(formData).subscribe(data => {
      this.fileObjResponse = data;
      if (data.result === "Failure") {
        this.utils.showErrorMsg("Something Went Wrong");
      } else {
        this.utils.showSuccessMsg("upload successfull")
      }
      if (this.uploadContentTemplate.value.operatorId === 'customOperator') {
        this.coloumnHeaders = Object.keys(data.dltFileInfo.columnHeaderMap).map(function (key) {
          return { key: key, value: data.dltFileInfo.columnHeaderMap[key] };
        });
      }
      this.uploadContentTemplate.patchValue({
        fileName: data.dltFileInfo.fileName
      });

    });

    // this.uploadContentTemplate.reset
  }




  submitForm() {
    let formArrayData = this.uploadContentTemplate.getRawValue();

    // if (this.fileList.length === 0) {
    //   this.utils.showErrorMsg('Please upload the CSV File')
    //   return;
    // }
    // formArrayData.fileName = this.fileList[0].name;

    let dttt: any = {}
    if (formArrayData.operatorId === 'customOperator') {
      dttt = {
        "userName": this.storageService.username,
        "fileType": formArrayData.fileType,
        "fileExtension": formArrayData.fileExtension,
        "operatorId": formArrayData.operatorId,
        "fileName": formArrayData.fileName,
        "customColumnMapping": {
          "TEMPLATE ID": formArrayData.customColumnMapping.TEMPLATE_ID,
          "TEMPLATE NAME": formArrayData.customColumnMapping.TEMPLATE_NAME,
          "TYPE": formArrayData.customColumnMapping.TYPE,
          "HEADER": formArrayData.customColumnMapping.HEADER,
          "CATEGORY": formArrayData.customColumnMapping.CATEGORY,
          "STATUS": formArrayData.customColumnMapping.STATUS,
          "TEMPLATE MESSAGE": formArrayData.customColumnMapping.TEMPLATE_MESSAGE
        },
        "entityId": formArrayData.description
      }
    } else {
      dttt = {
        "userName": this.storageService.username,
        "fileType": formArrayData.fileType,
        "fileExtension": formArrayData.fileExtension,
        "operatorId": formArrayData.operatorId,
        "fileName": formArrayData.fileName,
        "entityId": formArrayData.description,
        "customColumnMapping": { no: 'norecord' }
      }
    }

    this.templateService.uploadTemplate(dttt).subscribe(data => {
      if (data.result === "Failure") {
        this.utils.showErrorMsg(data.message);
      } else {
        // this.resetForm();
        this.utils.showSuccessMsg(data.message);
      }
    }, err => {
      this.utils.showErrorMsg("Something Went Wrong");
    })
    window.location.reload();
  }


  setDefaultData() {
    // Set Default Data Again on the page
    this.uploadContentTemplate.patchValue({
      fileType: 'HEADER_FILE',
      description: '',
      fileExtension: 'csv',
      senderId: '',
      entityId: '',
      operatorId: ''
    });
  }
  file:any;
  handleFile(files:any) {
    console.log(files)
    let file = files.target.files[0].name;
    console.log(file)
    var ext = file.split('.').pop();

    if(this.uploadContentTemplate.value.fileExtension !== ext) {
      this.file_err = true;
      this.file_err_msg = "Please upload correct file format, you are uploading ."+ext+" file and you have selected ."+this.uploadContentTemplate.value.fileExtension+" Template File Type option. Please check."
     this.fileObjResponse = true;
      this.myInputVariable.nativeElement.value = '';
    } else {
      this.file_err = false;
      this.file_err_msg = true;
      this.fileObjResponse = false;
    }

    this.file = files;
  }

  uploadReset() {

    this.uploadContentTemplate.reset();
    this.uploadContentTemplate.patchValue({
      fileType: 'HEADER_FILE',
      description: '',
      fileTypes: '',
      senderId: '',
      entityId: '',
      operatorId: ''
    });

  }


  buttontext(){

    //  alert(this.uploadContentTemplate.get('fileType')?.value)
      if(this.uploadContentTemplate.get('fileType')?.value == 'HEADER_FILE'){

        this.uploadshow = true;
        this.tempshow = false;
      }
       if(this.uploadContentTemplate.get('fileType')?.value == 'TEMPLATE_FILE'){
        this.uploadshow = false;
        this.tempshow = true;
      }

    }


  uploadfile(){
    // console.log(this.file);
    // console.log(this.file.srcElement.files[0].name)
    let formArrayData = this.uploadContentTemplate.getRawValue();
    if(this.file === undefined) {
      this.file_err = true
      this.file_err_msg = "Please upload ."+this.uploadContentTemplate.value.fileExtension+" Template File Type option selection. Please check."
      return;
    }
    let files = this.file.srcElement.files[0]
    var ext = files.name.split('.');
    if (!files) {
      return
    }
    let uploadedFiletype = files.name.split('.').pop();
    if ((uploadedFiletype == "csv") || (uploadedFiletype == "zip")
      || (uploadedFiletype == "xlsx")) {
    } else {
      this.tosterService.error('File Type Mismatch.', 'Error', {
        timeOut: 3000,
      });
      return
    }
    //ext[ext.length - 1])
     const formData:FormData = new FormData()
      formData.append('userName', this.storageService.username);
      // formData.append('fileType', this.uploadContentTemplate.get('fileType')?.value);
      formData.append('file', files);
      formData.append('fileType',this.uploadContentTemplate.get('fileType')?.value);
      formData.append('operatorId',this.uploadContentTemplate.get('operatorId')?.value);
      // formData.append('entityId',this.uploadContentTemplate.get('description')?.value);




     //  }
      this.templateService.uploadCustomDltDataaFile(formData).subscribe(res=>{
       console.log(res);
      alert(res.data.message);
      this.fileObjResponse = res;
      if(this.uploadContentTemplate.value.operatorId === 'customOperator') {
        this.coloumnHeaders = Object.keys(res.dltFileInfo.columnHeaderMap).map(function (key) {
          return { key: key, value: res.dltFileInfo.columnHeaderMap[key] };
      });
    }

      this.uploadContentTemplate.patchValue({
        fileName: res.dltFileInfo.fileName

      });

      });
    }

    selectDrop(val:any) {
      var dt = this.coloumnHeaders;
      this.coloumnHeaders = [];
      dt.forEach((element:any) => {
        if(element.key == val.value || element.select === true) {
          this.coloumnHeaders.push({
            "key": element.key,
            "value": element.value,
            "select": true
          })
        } else {
          this.coloumnHeaders.push({
            "key": element.key,
            "value": element.value,
            "select": false
          })
        }
      });
    }

    operatorIdChange() {
      this.myInputVariable.nativeElement.value = '';
      this.coloumnHeaders = [];
      this.fileObjResponse = false;
      this.file_err = false;
    }

  resetForm() {

    this.uploadContentTemplate.reset();
    this.setDefaultData();
  }
  // selectDrop(val:any) {
  //   var dt = this.coloumnHeaders;
  //   this.coloumnHeaders = [];
  //   dt.forEach((element:any) => {
  //     if(element.key == val.value || element.select === true) {
  //       this.coloumnHeaders.push({
  //         "key": element.key,
  //         "value": element.value,
  //         "select": true
  //       })
  //     } else {
  //       this.coloumnHeaders.push({
  //         "key": element.key,
  //         "value": element.value,
  //         "select": false
  //       })
  //     }
  //   });
  // }


}
