<!doctype html >
<html lang="en">

<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Bootstrap demo</title>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css" rel="stylesheet"
        integrity="sha384-GLhlTQ8iRABdZLl6O3oVMWSktQOp6b7In1Zl3/Jr59b6EGGoI1aFkw7cmDA6j6gD" crossorigin="anonymous">
</head>

<body>
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-10">
                    <h1 class="m-0"></h1>
                </div>
                <div class="grid grid-cols-8 gap-6 mt-2">
                    <div class="bg-white rounded-md shadow-md mb-8 col-span-12">
                        <div class="bg-gray-50 dark:bg-gray-700 p-6 border-b">
                            <p class="text-lg-start">User profile </p>
                        </div>
                        <div class="card">
                            <form [formGroup]="profileForm">
                                <div class=" shadow-lg card-body" style="top: 50%;">
                                    <div class="row p-1" style="margin-left: 25%; margin-bottom: 20%; margin-top: 5%; ">
                                        <div class="col sm-8" style="margin-left: 35px; margin-right: 65px;">
                                            <div class="mb-2 row">
                                                <label for="inputPassword" class="col-sm-2 col-form-label">User
                                                    name</label>
                                                <div class="col-sm-6">
                                                   
                                                  <p type="password" class="form-control"
                                                        formControlName="Username" >{{profileData}}</p>
                                                    
                                                </div>
                                            </div>

                                            <!-- <div class="mb-3 row">
                                                <label for="inputPassword"
                                                    class="col-sm-2 col-form-label">password</label>
                                                <div class="col-sm-6"> -->
                                                    <!-- <input type="text" class="form-control" formControlName="password"
                                                        [ngClass]="{'is-invalid':  !profileForm.controls.password.valid &&   profileForm.controls.password.touched ||  ! profileForm.controls.password.valid&& selected ,
                          'is-valid':   profileForm.controls.password.valid}"> -->
                                               <!-- <p class="border">{{passwordData}}</p>
                                                    <div
                                                        *ngIf=" profileForm.controls.password.invalid &&  profileForm.controls.password.touched || ! profileForm.controls.password.valid && selected">
                                                        <small
                                                            *ngIf=" profileForm.controls.password.hasError('required')"
                                                            class="text-danger">Field
                                                            is <b>required</b></small>
                                                    </div>
                                                </div>
                                            </div> -->

                                            <div class="mb-3 row">
                                                <label for="inputPassword" class="col-sm-2 col-form-label">Email
                                                    ID</label>
                                                <div class="col-sm-6" >
                                                    <!-- <input type="password" class="form-control" formControlName="Email"  
                                                        [ngClass]="{'is-invalid':  !profileForm.controls.Email.valid &&   profileForm.controls.Email.touched ||  ! profileForm.controls.Email.valid&& selected ,
                                                'is-valid':   profileForm.controls.Email.valid}"> -->
                                                  <!-- <p class="border">{{emailData}}</p> -->
                                                  <p type="password" class="form-control"
                                                        formControlName="Email" >{{emailData}}</p>

                                                    <!-- <div
                                                        *ngIf=" profileForm.controls.Email.invalid &&  profileForm.controls.Email.touched || ! profileForm.controls.Email.valid && selected">
                                                        <small *ngIf=" profileForm.controls.Email.hasError('required')"
                                                            class="text-danger">Field
                                                            is <b>required</b></small>
                                                    </div> -->
                                                </div>
                                            </div>


                                            <div class="mb-3 row">
                                                <label for="inputPassword" class="col-sm-2 col-form-label">Mobile
                                                    Number</label>
                                                <div class="col-sm-6">
                                                    <!-- <input type="password" class="form-control"
                                                        formControlName="mobileNumber" [ngClass]="{'is-invalid':  !profileForm.controls.mobileNumber.valid &&   profileForm.controls.mobileNumber.touched ||  ! profileForm.controls.mobileNumber.valid&& selected ,
                                                'is-valid':   profileForm.controls.mobileNumber.valid}"> -->
                                                <!-- <p class="border">{{mobileData}}</p> -->
                                                <p  class="form-control"
                                                        formControlName="mobileNumber" >{{mobileData}}</p>

                                                    <!-- <div
                                                        *ngIf=" profileForm.controls.mobileNumber.invalid &&  profileForm.controls.mobileNumber.touched || ! profileForm.controls.mobileNumber.valid && selected">
                                                        <small
                                                            *ngIf=" profileForm.controls.mobileNumber.hasError('required')"
                                                            class="text-danger">Field
                                                            is <b>required</b></small>
                                                    </div> -->
                                                </div>

                                            </div>
                                            <div class="mb-3 row">
                                                <label for="inputPassword"
                                                    class="col-sm-2 col-form-label" >organization</label>
                                                <div class="col-sm-6">
                                                    <p type="password" class="form-control"
                                                        formControlName="Organisation" >{{OrganisationData}}</p>
                                                      
                                                        <!-- <p class="border">{{OrganisationData}}</p> -->
                                                    <!-- <div
                                                        *ngIf=" profileForm.controls.Organisation.invalid &&  profileForm.controls.Organisation.touched || ! profileForm.controls.Organisation.valid && selected">
                                                        <small
                                                            *ngIf=" profileForm.controls.Organisation.hasError('required')"
                                                            class="text-danger">Field
                                                            is <b>required</b></small>
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="mb-3 row">
                                                <label for="inputPassword"
                                                    class="col-sm-2 col-form-label">Department</label>
                                                <div class="col-sm-6">
                                                    <!-- <input type="password" class="form-control"
                                                        formControlName="Department" [ngClass]="{'is-invalid':  !profileForm.controls.Department.valid &&   profileForm.controls.Department.touched ||  ! profileForm.controls.Department.valid&& selected ,
                                                'is-valid':   profileForm.controls.Department.valid}"> -->
                                                  <!-- <p class="border">{{DepartmentData}}</p> -->
                                                  <p type="password" class="form-control"
                                                        formControlName="Department" >{{DepartmentData}}</p>
                                                    <!-- <div
                                                        *ngIf=" profileForm.controls.Department.invalid &&  profileForm.controls.Department.touched || ! profileForm.controls.Department.valid && selected">
                                                        <small
                                                            *ngIf=" profileForm.controls.Department.hasError('required')"
                                                            class="text-danger">Field
                                                            is <b>required</b></small>
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col sm-5" style="margin-left: 20%;">
                                            <button  style="margin: 32px" type="submit" (click)="showModal()"
                                            class="btn btn-primary ">
                                            Update Password
                                        </button>
                                                </div>
                                            </div>
                                            <nz-modal [(nzVisible)]="isVisible" nzTitle="Update Password" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
                                                <ng-container *nzModalContent>
                                                    <!--  -->
                                                    <form nz-form [formGroup]="validateForm">
                                                        <nz-form-item>
                                                          <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="oldPassword">Old Password</nz-form-label>
                                                          <nz-form-control [nzSm]="14" [nzXs]="24">
                                                            <nz-input-group [nzSuffix]="suffixTemplateOld">
                                                              <input 
                                                                formControlName="oldPassword"
                                                                [type]="passwordVisibleOld ? 'text' : 'password'"  
                                                                nz-input
                                                                placeholder="Input old password"
                                                              />
                                                            </nz-input-group>
                                                          </nz-form-control>
                                                        </nz-form-item>
                                                      
                                                        <nz-form-item>
                                                          <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="newPassword">New Password</nz-form-label>
                                                          <nz-form-control [nzSm]="14" [nzXs]="24">
                                                            <nz-input-group [nzSuffix]="suffixTemplateNew">
                                                              <input 
                                                                formControlName="newPassword"
                                                                [type]="newPasswordVisible ? 'text' : 'password'"
                                                                nz-input
                                                                placeholder="Input new password"
                                                              />
                                                            </nz-input-group>
                                                          </nz-form-control>
                                                        </nz-form-item>
                                                      
                                                        <!-- Suffix templates -->
                                                        <ng-template #suffixTemplateOld>
                                                          <span
                                                            nz-icon
                                                            [nzType]="passwordVisibleOld ? 'eye-invisible' : 'eye'"
                                                            (click)="passwordVisibleOld = !passwordVisibleOld"
                                                          ></span>
                                                        </ng-template>
                                                      
                                                        <ng-template #suffixTemplateNew>
                                                          <span
                                                            nz-icon
                                                            [nzType]="newPasswordVisible ? 'eye-invisible' : 'eye'"
                                                            (click)="newPasswordVisible = !newPasswordVisible"
                                                          ></span>
                                                        </ng-template>
                                                      </form>
                                                      
                                                      

                                                </ng-container>
                                              </nz-modal>
                                            <!-- <div class="row">
                                                <div class="col sm-4" style="margin-left: 25%;">
                                                    <button style="margin: 32px" (click)="onsubmit()" type="submit"
                                                        class="btn btn-primary ">
                                                        Submit
                                                    </button>

                                                    <button type="reset" class=" btn btn-danger ms-2"
                                                        (click)="resetForm()"> Reset </button>
                                                </div>

                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- </div>
        </div>
    </div> -->

    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-w76AqPfDkMBDXo30jS1Sgez6pr3x5MlQ1ZAGC+nuZB+EYdgRZgiwxhTBTkF7CXvN"
        crossorigin="anonymous"></script>
</body>

</html>
