import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import *  as CryptoJS from 'crypto-js';
import { APP_NAME } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  getItem(arg0: string): any {
    throw new Error('Method not implemented.');
  }
  TemplateId: any;
  router: any;

  constructor() { }

  private encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, APP_NAME).toString();
  }

  private decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, APP_NAME).toString(CryptoJS.enc.Utf8);
  }

  public saveData(key: string, value: string) {
    localStorage.setItem(key, this.encrypt(value));
  }

  public getData(key: string) {
    let data = localStorage.getItem(key) || "";
    return this.decrypt(data);
  }

  set accessToken(token: string) {
    this.saveData('accessToken', token);
  }

  get accessToken(): string {
    const storedToken = this.getData('accessToken');

    // Check if the stored token is valid (e.g., not expired)
    if (this.isTokenValid(storedToken)) {
      return storedToken;
    } else {
      this.router.navigateByUrl('/login');

          //   this.router.navigateByUrl('/dashboard');
      //   return;
      // }
      // If the token is not valid, clear it and redirect to login
      // this.clearStorage();
      window.location.href = '/login'; // Replace with your login URL
      return ''; // Return an empty string
    }
  }
  private isTokenValid(token: string): boolean {
    // Implement your logic to check if the token is valid,
    // for example, checking if it's not expired or revoked.
    // You may need to decode the token and check its expiration date.
    // If it's valid, return true; otherwise, return false.
    // Example pseudocode:
    // const decodedToken = decodeToken(token);
    // return !isTokenExpired(decodedToken);
    return true; // Replace with your actual token validation logic.
  }

  clearStorage() {
    // localStorage.clear();
    document.cookie = "";
  }

  set user(value: any) {
    this.saveData('userData', JSON.stringify(value));
  }


  get user$(): Observable<any> {
    let userData: any = JSON.parse(this.getData('userData') ?? '');
    console.log(userData)
    return of(userData);
  }

  get username() {
    if(JSON.parse(this.getData('userData')).username == localStorage.getItem('username') || localStorage.getItem('username')==null){
    return JSON.parse(this.getData('userData')).username;
    }else{
    return localStorage.getItem('username')
    }
  }


  get userId() {
    if(JSON.parse(this.getData('userData')).userId == localStorage.getItem('userId') || localStorage.getItem('userId')==null){
      return JSON.parse(this.getData('userData')).userId;
      }else{
      return localStorage.getItem('userId')
      }
    // return JSON.parse(this.getData('userData')).userId;
  }

  get logo() {
    return JSON.parse(this.getData('userData')).logoUrl;
  }

  get role() {
    return JSON.parse(this.getData('userData')).role;
  }

  get userPrivilage() {
    return JSON.parse(this.getData('userData')).userPrivileges;
  }
  get userAllAccount() {
    return JSON.parse(this.getData('userData')).userAllAccounts;
  }



  // get userID() {
  //   return JSON.parse(localStorage.getItem('userData') ?? '').username;
  // }
}
