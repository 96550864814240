
<!-- <div nz-row nzJustify="space-between" [nzGutter]="24" class="nav" >
   

    <div nz-col [nzSm]="4" >
        <span   class="trigger"
        nz-icon
        [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
        (click)="isCollapsed = !isCollapsed"></span>

    </div>

    <div nz-col [nzSm]="12" *ngIf="dasboardCount">

        <div class="dashboard-stats">

            <nz-space nzSize="middle">
                <span *nzSpaceItem class="stats-counter"><small>
                    <span class="count">{{dasboardCount?.totalSmsToday ? dasboardCount?.totalSmsToday : '0' }}</span>
                    &nbsp; SMS Count Today</small>
                </span>

                <span *nzSpaceItem class="stats-counter"><small>
                    <span class="count">{{dasboardCount?.totalSmsWeek ? dasboardCount?.totalSmsWeek : '0' }}</span>
                    &nbsp; SMS Count this Week</small>
                </span>

                <span *nzSpaceItem class="stats-counter"><small>
                    <span class="count">{{dasboardCount?.totalSmsMonth ? dasboardCount?.totalSmsMonth : '0' }}</span>
                    &nbsp; SMS Count Month</small>
                </span>
            </nz-space>
                <nz-space nzSize="middle">
              


            </nz-space>
        </div>
    </div>

    <div nz-col [nzSm]="6">
        <nz-space nzSize="large">
           

            <button id="sd" nz-button *nzSpaceItem nzType="primary" nz-dropdown [nzDropdownMenu]="menu" nzClickHide="false"
            [(nzVisible)]="visible">
            <span nz-icon nzType="user"></span>
            {{userName}}
        </button>


        <nz-dropdown-menu  #menu="nzDropdownMenu">
            <ul nz-menu id="as">
                <li nz-menu-item  (click)="profile()">View Profile</li>
                <li nz-menu-item (click)="visible = false; exitApp()">Exit App</li>
            </ul>
        </nz-dropdown-menu>
     </nz-space>
    </div>
</div> -->
<!-- <div nz-row nzJustify="space-between" class="nav" >

    <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 7 }">
          <span   class="trigger"
        nz-icon
        [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
        (click)="isCollapsed = !isCollapsed"></span> -->
      <!-- <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a user" [(ngModel)]="selectedValue" (ngModelChange)="switchUser()"> -->

        <!-- <nz-option nzLabel="{{userName}}" nzValue="{{userName}}"></nz-option> -->
        <!-- <nz-option nzLabel="{{user.key}}" nzValue="{{user.key}}||~~||{{user.value}}" *ngFor="let user of userAllAccount | keyvalue"></nz-option> -->

      <!-- </nz-select> -->
    <!-- </div> -->

    <!-- <div nz-col [nzSm]="12" *ngIf="dasboardCount">

        <div class="dashboard-stats"  >

            <nz-space nzSize="middle"  *ngIf="!isSmallScreen">
                <span *nzSpaceItem class="stats-counter">
                    <span class="count" >{{dasboardCount?.totalSmsToday ? dasboardCount?.totalSmsToday : '0' }}</span>
                    &nbsp; SMS Count Today
                </span>

                <span *nzSpaceItem class="stats-counter">
                    <span class="count">{{dasboardCount?.totalSmsWeek ? dasboardCount?.totalSmsWeek : '0' }}</span>
                    &nbsp; SMS Count this Week
                </span>

                <span *nzSpaceItem class="stats-counter">
                    <span class="count">{{dasboardCount?.totalSmsMonth ? dasboardCount?.totalSmsMonth : '0' }}</span>
                    &nbsp; SMS Count Month
                </span>
            </nz-space>
                <nz-space nzSize="middle" *ngIf="!isSmallScreen">
                 <span *nzSpaceItem class="stats-counter">
                    <span class="count">{{dasboardCount?.availableCredits ? dasboardCount?.availableCredits : '0'
                        }}</span>
                    &nbsp; Credits
                </span> -->


            <!-- </nz-space> -->
        <!-- </div> -->
    <!-- </div>  -->

    <!-- <div nz-col [nzXs]="{ span: 6, offset: 10 }" [nzLg]="{ span: 4, offset: 0 }">
        <nz-space nzSize="middle"> -->
            <!-- <button nz-button *nzSpaceItem nzType="default" nzSize="large" nzShape="circle">
                <span nz-icon nzType="notification"></span>
            </button>
            <button nz-button *nzSpaceItem nzType="default" nzSize="large" nzShape="circle">
                <span nz-icon nzType="setting"></span>
            </button> -->

            <!-- <button id="sd" nz-button *nzSpaceItem nzType="primary" nz-dropdown [nzDropdownMenu]="menu" nzClickHide="false"
            [(nzVisible)]="visible">
            <span nz-icon nzType="user"></span>
            {{userName}}
        </button> -->


        <!-- <nz-dropdown-menu  #menu="nzDropdownMenu">
            <ul nz-menu id="as">
                <li nz-menu-item  (click)="profile()">View Profile</li>
                <li nz-menu-item (click)="visible = false; exitApp()">Exit App</li>
            </ul>
        </nz-dropdown-menu> -->
     <!-- </nz-space>
    </div>
</div>  -->


<div nz-row nzJustify="space-between" [nzGutter]="24" class="nav" >


    <div nz-col [nzSm]="4"   >
    </div>

    <div nz-col [nzSm]="12" *ngIf="dasboardCount">

        <div class="dashboard-stats">

            <nz-space nzSize="middle" *ngIf="!isSmallScreen" >
                <span *nzSpaceItem class="stats-counter"><small>
                    <span class="count">{{dasboardCount?.totalSmsToday ? dasboardCount?.totalSmsToday : '0' }}</span>
                    &nbsp; SMS Count Today</small>
                </span>

                <span *nzSpaceItem class="stats-counter"><small>
                    <span class="count">{{dasboardCount?.totalSmsWeek ? dasboardCount?.totalSmsWeek : '0' }}</span>
                    &nbsp; SMS Count this Week</small>
                </span>

                <span *nzSpaceItem class="stats-counter"><small>
                    <span class="count">{{dasboardCount?.totalSmsMonth ? dasboardCount?.totalSmsMonth : '0' }}</span>
                    &nbsp; SMS Count Month</small>
                </span>
            </nz-space>
                <nz-space nzSize="middle"  >
              


            </nz-space>
        </div>
    </div>

    <div nz-col [nzSm]="3" >
        <nz-space nzSize="large" class="user-dropdown" >
            <button id="sd" nz-button *nzSpaceItem nzType="primary" nz-dropdown [nzDropdownMenu]="menu" nzClickHide="false" [(nzVisible)]="visible">
                <span nz-icon nzType="user"></span>
                {{ userName }}
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu" class="dropdown-menu" >
                <ul nz-menu id="as">
                    <li nz-menu-item (click)="profile()">View Profile</li>
                    <li nz-menu-item (click)="visible = false; exitApp()">Exit App</li>
                </ul>
            </nz-dropdown-menu>
        </nz-space>
    </div>
    
</div>
