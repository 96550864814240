
<h4 nz-typography>Dynamic Message</h4>

<div class="form-input">
    <form nz-form [formGroup]="dynamicCampaignForm">
        <nz-form-item>
            <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Campaign Name</nz-form-label>
            <nz-form-control [nzSm]="10" [nzValidatingTip]="note" [nzErrorTip]="nameErrorTpl">
                <input nz-input placeholder="" formControlName="campaignName" />
                <ng-template #nameErrorTpl let-control>
                    <ng-container *ngIf="control.hasError('required')">required
                    </ng-container>
                    <ng-container *ngIf="control.hasError('pattern')">{{note}}</ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Message Encoding</nz-form-label>
            <nz-form-control [nzSm]="10"  style="margin-left: 1px;">
                <nz-radio-group formControlName="fileType">
                    <label nz-radio nzValue="plain">SMS</label>
                    <label nz-radio nzValue="FL">Flash</label>
                </nz-radio-group>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Select File</nz-form-label>
            <nz-form-control [nzSm]="10"  style="margin-left: 5px;">
                <nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload" nzAccept=".xlsx,.csv,.zip">
                    <button nz-button>
                        <span nz-icon nzType="upload" style="margin-left: 1px;"></span>
                        Select File
                    </button>
                </nz-upload>

               

                <i><small>Note : Only .xlsx, .csv and zip files are allowed</small></i>
                <a nz-button nzType="link" (click)="open()">Sample File of Dynamic Campaign</a>
            </nz-form-control>
            <button nz-button [nzType]="'primary'" [nzLoading]="uploading" (click)="handleUpload()"
                [disabled]="fileList.length === 0" style="margin-right: 34%;">Upload</button>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label class="txt-left" [nzSm]="5" [nzXs]="10" nzRequired>Message Type</nz-form-label>
            <nz-form-control  style="margin-left: 1px;">
                <nz-radio-group nzButtonStyle="solid" formControlName="serviceType" [(ngModel)]="messageType"
                    (ngModelChange)="getSenderIdsByMsgType($event)">
                    <label nz-radio-button nzValue="trans">Transactional</label>
                    <label nz-radio-button nzValue="promo">Promotional</label>
                    <label nz-radio-button nzValue="others">Service</label>
                </nz-radio-group>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="messageType === 'others'">
            <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Service Type</nz-form-label>
            <nz-form-control>
                <nz-radio-group nzButtonStyle="solid" formControlName="serviceSubType"
                    (ngModelChange)="getSenderIdsByMsgType(messageType,$event)">
                    <label nz-radio-button nzValue="service-implicit">Service Implicit</label>
                    <label nz-radio-button nzValue="service-explicit">Service Explicit</label>
                </nz-radio-group>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Sender ID</nz-form-label>
            <nz-form-control [nzSm]="10">
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Sender Id" formControlName="senderId"
                    (ngModelChange)="getTemplates(messageType,$event)" [(ngModel)]="senderIdSelected">
                    <nz-option [nzLabel]="item.senderId" [nzValue]="item.senderId"
                        *ngFor="let item of listOfSenderIds">
                    </nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Content Template Name</nz-form-label>
            <nz-form-control [nzSm]="10">
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Template"
                    formControlName="templateName"
                    (ngModelChange)="templateSelected = $event;messageText = templateSelected?.templateText">
                    <nz-option [nzLabel]="item.templateTitle" [nzValue]="item"
                        *ngFor="let item of listOfTemplates">
                    </nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Mobile Number</nz-form-label>
            <nz-form-control [nzSm]="10">
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Mobile Number"
                    formControlName="mobileNumber">
                    <nz-option [nzLabel]="item.key" [nzValue]="item.value" *ngFor="let item of coloumnHeaders">
                    </nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label class="txt-left" [nzSm]="5" nzRequired>column List</nz-form-label>
            <nz-form-control [nzSm]="10">
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select List" formControlName="columnList">
                    <nz-option [nzLabel]="item.key" [nzValue]="item.value" *ngFor="let item of coloumnHeaders">
                    </nz-option>
                </nz-select>
            </nz-form-control>
            &nbsp;&nbsp;&nbsp;
            <button nz-button [nzType]="'primary'" (click)="addColoumToText(textArea)">Add To Text</button>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Message Text</nz-form-label>
            <nz-form-control [nzSm]="10">
                <nz-textarea-count>
                    <!-- [ngModel]="templateSelected?.templateText" -->
                    <textarea rows="4" #textArea formControlName="msgText" nz-input (input)="msgLength()"  placeholder="Message Text"
                        [(ngModel)]="messageText"></textarea>
                </nz-textarea-count>
                <a style="margin-left: 5px;">
                    {{msgTextLength ==0?messageText.length:msgTextLength }} characters | {{messageText | creditCount }} SMS Credit
                <br />
                <small><i><b>Note:</b> xxxxxx will be replaced by actual shortcode up to 6 chars.</i></small></a>
            </nz-form-control>
        </nz-form-item>


        <nz-form-item>
            <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Convert Short URL</nz-form-label>
            <nz-form-control [nzSm]="10"  style="margin-left: 1px;">
                <nz-radio-group formControlName="convertShortUrl" [(ngModel)]="convertShortUrl">
                    <label nz-radio nzValue="Y">Yes</label>
                    <label nz-radio nzValue="N">No</label>
                </nz-radio-group>
            </nz-form-control>
        </nz-form-item>



        <nz-form-item *ngIf="convertShortUrl === 'Y'">
            <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Select Domain</nz-form-label>
            <nz-form-control [nzSm]="10">
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Domain"
                    formControlName="userDomain">
                    <nz-option [nzLabel]="item.domainName" [nzValue]="item.domainName"
                        *ngFor="let item of listActiveHost">
                    </nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>


        <nz-form-item *ngIf="convertShortUrl === 'Y'">
            <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Callback URL</nz-form-label>
            <nz-form-control [nzSm]="10">
                <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                    <input type="text" nz-input formControlName="callbackUrlValue" />
                </nz-input-group>
                <ng-template #addOnBeforeTemplate>
                    <nz-select [ngModel]="" formControlName="callbackUrl" style="width: 100px;">
                        <nz-option nzLabel="http://" nzValue="http://"></nz-option>
                        <nz-option nzLabel="https://" nzValue="https://"></nz-option>
                    </nz-select>
                </ng-template>
                <small style="word-break: break-all;" ><i><b>Sample URL</b> :
                        google.com?mobile=%MOBILE%&camp=%CAMPAIGN_NAME%&rectime=%RECTIME%&circle=%CIRCLE%&operator=%OPERATOR%</i></small>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Entity ID</nz-form-label>
            <nz-form-control [nzSm]="10">
                <input nz-input placeholder="" formControlName="entityId" placeholder="Entity ID"
                    [ngModel]="templateSelected?.entityId" />
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Operator Template Id</nz-form-label>
            <nz-form-control [nzSm]="10">
                <input nz-input placeholder="" formControlName="dltTemplateId" placeholder="Operator Template Id"
                    [ngModel]="templateSelected?.operatorTemplateId" />
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Schedule Message</nz-form-label>
            <nz-form-control [nzSm]="10" style="margin-left: 1px;">
                <nz-radio-group formControlName="scheduleMessage" [(ngModel)]="scheduleMessage">
                    <label nz-radio nzValue="yes">Yes</label>
                    <label nz-radio nzValue="no">No</label>
                </nz-radio-group>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="scheduleMessage === 'yes' && splitFile === 'no'">
            <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Schedule Date & Time</nz-form-label>
            <nz-form-control [nzSm]="10">
                <nz-date-picker
                nzFormat="yyyy-MM-dd HH:mm"
                [nzDisabledDate]="disabledDate"
                [nzDisabledTime]="disabledDateTime"
                [(ngModel)]="selectedDate"
                formControlName="scheduleDateTime"
                [nzShowNow]="false"
                [nzShowTime]="{ nzDefaultOpenValue: timeDefaultValue, nzFormat: 'HH:mm'  }">
                   </nz-date-picker>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="scheduleMessage === 'yes' && splitFile === 'yes'">
            <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Schedule Date</nz-form-label>
            <nz-form-control [nzSm]="8">
              <nz-date-picker
                nzFormat="yyyy-MM-dd"
                [nzDisabledDate]="disabledDate"
                [(ngModel)]="selectedDate"
                formControlName="scheduleDateTime"
                [nzShowNow]="false">
              </nz-date-picker>
            </nz-form-control>
          </nz-form-item>



        <nz-form-item *ngIf="scheduleMessage === 'yes'">
            <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Split File</nz-form-label>
            <nz-form-control [nzSm]="8">
                <nz-radio-group formControlName="splitFile" [(ngModel)]="splitFile">
                    <label nz-radio class="sd" nzValue="yes">Yes</label>
                    <label nz-radio nzValue="no">No</label>
                </nz-radio-group>
            </nz-form-control>
        </nz-form-item>

        <!-- {{splitPart.controls.length}} -->
        <ng-container formArrayName="splitPart" *ngIf="splitFile === 'yes' && scheduleMessage === 'yes'">
            <ng-container *ngFor="let splitPartForm of splitPart.controls; let i=index" [formGroupName]="i">
                <div nz-row nzGutter="12">

                    <div nz-col [nzSm]="10">
                        <nz-form-item>
                            <nz-form-control>

                            </nz-form-control>
                        </nz-form-item>
                    </div>


                    <div nz-col nzSpan="2">
                        <nz-form-item>
                            <nz-form-control class="vv">
                                <input nz-input placeholder="From" formControlName="from" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col nzSpan="2">
                        <nz-form-item>
                            <nz-form-control class="vv">
                                <input nz-input placeholder="To" formControlName="to" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col nzSpan="2">
                        <nz-form-item>
                            <nz-form-control class="vv">
                                <nz-select nzPlaceHolder="Hour" formControlName="hh">
                                    <nz-option [nzLabel]="item" [nzValue]="item" [nzDisabled]="isHourDisabled(item)"
                                        *ngFor="let item of hourOption">
                                    </nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col nzSpan="2" class="vv">
                        <nz-form-item>
                          <nz-form-control>
                            <nz-select nzPlaceHolder="Minute" formControlName="mm">
                              <nz-option [nzLabel]="item" [nzValue]="item" *ngFor="let item of minuteOption" [nzDisabled]="isMinuteDisabled(item)">
                              </nz-option>
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                      </div>




                    <div nz-col nzSpan="2" class="vv">
                        <button nz-button nzType="primary" (click)="addSplitArray()"
                        *ngIf="i === 0">Add</button>
                        <button nz-button nzType="primary" (click)="removeSplitArray(i)"
                            *ngIf="i !== 0">Remove</button>
                    </div>

                </div>
            </ng-container>
        </ng-container>

        <nz-form-item>
            <nz-form-control style="margin-left: 42%;">
                <button nz-button nzType="primary"(click)="submitForm()">Submit</button>
                <button nz-button (click)="resetForm()">Clear</button>
                <nz-button-group>
                  <!-- <button nz-button nzType="primary" rel="stylesheet" (click)="downloadFile()" href="/home/apps/applications/webtool-server/webapps/files/Dynamic_campaign_sample_file.xlsx"><span nz-icon nzType="cloud-download"></span>Download sample  File</button> -->
                </nz-button-group>
            </nz-form-control>
        </nz-form-item>
    </form>
</div>


<nz-modal [(nzVisible)]="isVisible" nzTitle="Confirm Request" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()" [nzOkText]="'Send Now'"
[nzOkLoading]="isOkLoading">
<ng-container *nzModalContent>
<!-- <app-simple-table [listOfData]="previewData?.previewData" [listOfColumn]="listOfColumn" [type]="'dynamic'">
</app-simple-table> -->


<nz-table #basicTable [nzData]="previewDataList">
<thead>
  <tr>
    <th>Mobile</th>
    <th>Message</th>
  </tr>
</thead>
<tbody>
  <tr *ngFor="let data of basicTable.data">
    <td>{{ data.key }}</td>
    <td>{{ data?.value }}</td>
</tr>
</tbody>
</nz-table>
<small>Total Count : {{previewData?.totalCount}} | Plain Count : {{previewData?.plainCount}} | Plain Credit :
    {{previewData?.plainCredit}} | Unicode Count : {{previewData?.unicodeCount}} | Unicode Credit :
    {{previewData?.unicodeCredit}}</small>

</ng-container>
</nz-modal>

<nz-drawer
[nzClosable]="false"
[nzVisible]="visible"
nzPlacement="right"
nzTitle="Sample Dynamic Campaign File"
(nzOnClose)="close()"
>
<ng-container *nzDrawerContent>
    <!-- <img *ngIf="imageUrl" [src]="imageUrl" alt="Dynamic Image" /> -->
    <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
        <thead>
          <tr>
            <th style="border: 1px solid black; padding: 8px;">Mobile</th>
            <th style="border: 1px solid black; padding: 8px;">ID</th>
            <th style="border: 1px solid black; padding: 8px;">Message</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="border: 1px solid black; padding: 8px;">123456789</td>
            <td style="border: 1px solid black; padding: 8px;">1</td>
            <td style="border: 1px solid black; padding: 8px;">test</td>
          </tr>
        </tbody>
      </table>
</ng-container>
</nz-drawer>