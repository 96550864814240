import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { APP_NAME } from 'src/environments/environment';
import { AuthGuardGuard } from './services/auth-guard/auth-guard.guard';
import { StorageService } from './services/storage/storage.service';
import { ColourService } from './services/colour.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @Input() sidebarshow: boolean = false;
  isCollapsed = false;
  title = 'gui-2';
  pageName: string = '';
  appName = APP_NAME;
  sidebar: any;
  isAuthenticated: boolean = false;
  isSmallScreen = false;
  email: string | null = null; // Store the extracted email

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenSize();
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public storageService: StorageService,
    private colourService: ColourService,
    private authGuard: AuthGuardGuard,
    private activatedRoute: ActivatedRoute,
  ) {
    this.authGuard.loggedInUser.subscribe(isLoggedIn => {
      this.isAuthenticated = isLoggedIn;
    });
  }

  onClick() {
    this.sidebar = !this.sidebar;
  }

  getSession(): boolean {
    let token = this.storageService.accessToken;
    return token ? true : false;
  }

  ngOnInit(): void {

  


    this.checkScreenSize();

    this.initializeRouterEvents();
  }

  checkScreenSize(): void {
    this.isSmallScreen = window.innerWidth <= 768; // Adjust the breakpoint as needed
    this.isCollapsed = this.isSmallScreen; // Set isCollapsed to true if screen width is less than or equal to 768px
  }

  toggleMenu() {
    this.isCollapsed = !this.isCollapsed;
    console.log('Menu is collapsed:', this.isCollapsed);
  }



  initializeRouterEvents(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(data => {
        this.pageName = data['pageName'];
      });
  }
}
