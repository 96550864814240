import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { CreditCountPipe } from 'src/app/pipe/credit-count.pipe';
import { CampaignService } from 'src/app/services/campaign/campaign.service';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { TemplateService } from 'src/app/services/templates/template.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { CAMPAIGN_NAME_HINT, CAMPAIGN_NAME_REGEX, PLAIN_TXT_REGEX } from 'src/environments/environment';
import { DisabledTimeFn, DisabledTimePartial } from 'ng-zorro-antd/date-picker';
import { SAMPLE_DYNAMIC_DOWNLOAD} from 'src/environments/environment';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-dynamic-campaign',
  templateUrl: './dynamic-campaign.component.html',
  styleUrls: ['./dynamic-campaign.component.scss']
})
export class DynamicCampaignComponent implements OnInit {

  timeDefaultValue: Date | undefined;
  imageUrl: string;
  previewDataList:any=[];
  selectedDate = new Date();
  visible = false;
  hourOption: string[] = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
  minuteOption: string[] = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"];

  dynamicCampaignForm = this.fb.group({
    senderId: [null, [Validators.required]],
    entityId: ['', Validators.required],
    campaignName: [null, [Validators.required, Validators.pattern(CAMPAIGN_NAME_REGEX)]],
    serviceType: 'trans',
    serviceSubType: 'service-implicit',
    dltTemplateId: ['', [Validators.required]],
    msgText: ['', [Validators.required]],
    msgType: "plain",
    perMsgCredit: "",
    templateName: [''],
    loggedInUserName: this.storageService.username,
  
    fileType: 'plain',
    scheduleMessage: 'no',
    uploadedDynamicfileName: [],
    scheduleInfo: { splitPart: [] },
    splitFile: 'no',
    splitPart: this.fb.array([]),
    scheduleDateTime: '',
    scheduleDate: '',

    mobileNumber: ['', Validators.required],
    convertShortUrl: "N",
    userDomain: '',
    callbackUrl: '',
    callbackUrlValue: '',
    columnList: '',
    contentTemplateId: ''




  });

  listOfColumn = [
    {
      title: 'Mobile',
      compare: (a: any, b: any) => a.key - b.key,
      priority: false
    },
    {
      title: 'Message',
      compare: (a: any, b: any) => a.value - b.value,
      priority: false
    }
  ];

  messageType: string = 'trans';
  note: string = CAMPAIGN_NAME_HINT;
  templateSelected: any;
  listOfSenderIds: any;
  listOfTemplates: any;
  senderIdSelected: any;
  convertShortUrl: string = 'N';
  messageText: string = '';
  listOfURLSelected: string[] = [];
  fileList: any[] = [];
  scheduleMessage: string = 'no';
  splitFile: string = 'no';
  uploading = false;
  scheduleDateTime: any;
  scheduleDate: any;
  coloumnHeaders: any;
  listActiveHost: any;
  previewData: any;
  dynamicPreviewFormData:any;

  isVisible: boolean = false;
  isOkLoading: boolean = false;
  msgTextLength:number=0;



  disabledDate = (date: Date): boolean => {
    const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1); // get yesterday's date
  return date < yesterday;
}

  constructor(
    private fb: FormBuilder,
    private storageService: StorageService,
    private templateService: TemplateService,
    private campaignService: CampaignService,
    private reportService: ReportService,
    private utilService: UtilsService,
    private creditCountPipe: CreditCountPipe
  )   {
    const currentDate = new Date();
    const fifteenMinutesAgo = new Date(currentDate.getTime() + 15 * 60000);
    this.selectedDate = fifteenMinutesAgo;
    this.imageUrl = 'assets/images/your-image.jpg';
    }


    disabledDateTime: DisabledTimeFn = (current: Date | null | Date[]) => {
      const today = new Date();
      let currentHour: number | undefined;

      if (current instanceof Array) {
        currentHour = current[0].getHours();
      } else {
        currentHour = current?.getHours();
      }

      const isToday = current instanceof Array ? current[0].getDate() === today.getDate() : current?.getDate() === today.getDate();

      if (!isToday) {
        return undefined;
      }

      return {
        nzDisabledHours: () => {
          if (today.getHours() === 0) {
            return this.range(0, 24).splice(1);
          }
          return this.range(0, 24).splice(0, today.getHours());
        },
        nzDisabledMinutes: () => {
          if (today.getHours() === currentHour) {
            return this.range(0, 60).splice(0, today.getMinutes() + 15);
          }
          return [];
        },
        nzDisabledSeconds: () => {
          if (today.getSeconds() === 0) {
            return this.range(0, 60).splice(1);
          }
          return this.range(60, 60).splice(0, today.getSeconds());
        }
      };
    };

    downloadFile() {
      //downloadSampleFile
      const fileURL = SAMPLE_DYNAMIC_DOWNLOAD;
      const a = document.createElement('a');
      a.href = fileURL;
      a.download = 'Dynamic_campaign_sample_file.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }




    range(start: number, end: number): number[] {
      const result: number[] = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    }
    isHourDisabled(hour: string): boolean {
      const today = new Date();
      const currentHour = today.getHours();
      const selectedDate = this.selectedDate; // Assuming this.selectedDate is the selected date

      if (!selectedDate) {
        return false; // Return false if selectedDate is null or undefined
        }

      // Check if the selected date is today
      if (selectedDate.toDateString() === today.toDateString()) {
        // Convert the selected hour to a number
        const selectedHour = parseInt(hour, 10);

        // Check if the selected hour is less than the current hour
        if (selectedHour < currentHour) {
          return true; // Disable the hour
        }
      }

      return false;
    }




    isMinuteDisabled(minute: string): boolean {
      const today = new Date();
      const currentHour = today.getHours();
      const currentMinute = today.getMinutes();
      const selectedDate = this.selectedDate; // Assuming this.selectedDate is the selected date

      if (!selectedDate) {
        return false; // Return false if selectedDate is null or undefined
        }

      console.log(this.dynamicCampaignForm.get('splitPart')?.value[0].hh)
      // Check if the selected date is today
      if (selectedDate.toDateString() === today.toDateString() && currentHour == this.dynamicCampaignForm.get('splitPart')?.value[0].hh) {
        // Check if the selected hour is the current hour

        if (parseInt(minute) <= currentMinute ) {

          return true; // Disable the minute for previous minutes in the current hour

        }
        return false;
      }
      return false;


    }



  ngOnInit(): void {
    this.getSenderIdsByMsgType();
    this.genCampaignName();
    this.addSplitArray();
    this.getActiveDomains();
  }
  lengthWithoutNewlines(str: string) {
    return str.replace(/\r/g, '').replace(/\n/g, '').length;
  }
  msgLength(){
    var sectionToCheck = this.dynamicCampaignForm.get('msgText')?.value;
   var allFoundCharacters = sectionToCheck.match(/[\[\]\{\}\\|\^€\~]/g);
   var splCharCount = (allFoundCharacters) ? allFoundCharacters.length : 0;//count
   var charCount = this.lengthWithoutNewlines(this.dynamicCampaignForm.get('msgText')?.value) + parseInt(splCharCount);
   this.msgTextLength = charCount;
 }


  handleUpload(): void {
    let files = this.fileList[0]
    if (!files) {
      return
    }

    let uploadedFiletype = files.name.split('.').pop();
    if ((uploadedFiletype == "csv") || (uploadedFiletype == "zip")
      || (uploadedFiletype == "xlsx")) {
    } else {
      this.utilService.showErrorMsg('File Type Mismatch.');
      return;
    }

    let formData: FormData = new FormData();
    formData.append('file', files);
    formData.append("userName", this.storageService.username);
    formData.append("fileType", this.dynamicCampaignForm.value.fileType);
    formData.append("campaignName", this.dynamicCampaignForm.value.campaignName);
    formData.append("msgType", 'plain');


    this.campaignService.uploadDynamicFile(formData).subscribe(data => {
      // this.fileObjResponse = data;
      let test = data.data.columnHeaderMap;
      this.coloumnHeaders = Object.keys(test).map(function (key) {
        return { key: key, value: test[key] };
      });
      this.dynamicCampaignForm.patchValue({
        fileName: data.data.fileName,
        uploadedDynamicfileName: data.data.uploadedDynamicfileName
      });
      this.utilService.showSuccessMsg(data.message)
    });
  }

  addColoumToText(msgTextArea: any) {
    let columnValue = this.dynamicCampaignForm.value.columnList;
    this.updatedMsgTextwithColoumn(columnValue, msgTextArea)
  }

  updatedMsgTextwithColoumn(field: any, msgTextArea: any) {
    var msgText = this.dynamicCampaignForm.value.msgText;
    const hasVar = msgText?.includes("{#var#}");
    var cursorPos = hasVar ? msgText.indexOf('{#var#}') : this.campaignService.getCaretPos(msgTextArea);
    var length = hasVar ? 7 : 0;
    var textBefore = msgText.substring(0, cursorPos);
    var textAfter = msgText.substring(cursorPos + length, msgText.length);
    this.dynamicCampaignForm.patchValue({
      msgText: textBefore + "" + field + "" + textAfter
    });
  }

  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }

  submitForm() {

    if (this.dynamicCampaignForm.valid) {
      let params = this.dynamicCampaignForm.getRawValue();
      params.perMsgCredit = this.creditCountPipe.transform(params.msgText);
      if (params.convertShortUrl === 'Y') {
        params.userDomain = params.userDomain;        
      }

      if (params?.contentTemplateId != null) {
        params.contentTemplateId = params?.templateName?.templateId;
      }

      delete params.templateName;
      delete params.fileType;
      if (params.serviceType !== "service") {
        delete params.messageSubType;
      }

      if (params.scheduleMessage !== "no") {
        const schDate = params.splitFile === "yes" ? this.scheduleDate : this.scheduleDateTime;
        // console.log(schDate)
        // let schDate = this.utilService.formatDate(params.scheduleDate,'YYYY-MM-DD');
        params.scheduleDate = this.utilService.formatDate(this.selectedDate, 'YYYY-MM-dd');
        // params.scheduleDate = new Date(schDate).toLocaleDateString();
        params.scheduleHour = new Date(this.selectedDate).getHours();
        params.scheduleMinute = new Date(this.selectedDate).getMinutes();
        delete params.scheduleDateTime;
      }  else {
        delete params.scheduleDateTime;
        delete params.scheduleDate;
      }

      delete params.splitPart;
      delete params.callbackUrlValue;

      this.campaignService.previewDynamicSMSCampaign(params).subscribe(data => {
        var result = Object.keys(data.previewDataMap).map(function (key) {
          return { key: key.split('Æ')[0], value: data.previewDataMap[key] };
        });
        data.previewData = result;
        this.previewDataList=data.previewData
        console.log(data.previewData)
        this.previewData = data;

        this.dynamicPreviewFormData = params;

        this.isVisible = true;

      }, error => {
        this.utilService.showErrorMsg('Something Went Wrong');
      })

    } else {
      Object.values(this.dynamicCampaignForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  submitDynamicCampaign() {


    if (this.dynamicPreviewFormData.splitFile === "yes") {
      const newArr = this.dynamicPreviewFormData.splitPart.map((element:any, index:any) => {
        element.id = (index + 1);
        return element;
      });
      this.dynamicPreviewFormData.scheduleInfo.splitPart = newArr;
      delete this.dynamicPreviewFormData.splitPart;
    } else {
      delete this.dynamicPreviewFormData.splitPart;
    }

    delete this.dynamicPreviewFormData.templateName;

    this.campaignService.sendDynamicSMS(this.dynamicPreviewFormData).subscribe(data => {
      this.resetForm();
      this.isVisible = false;
      this.isOkLoading = false;
      if (data.result === "Success") {
        this.utilService.showSuccessMsg(data.message);
        setTimeout(() => {
          window.location.reload();
        },  1000);
      } else {
        this.utilService.showErrorMsg(data.message);
      }
    }, err => {
      this.utilService.showErrorMsg('Something Went Wrong');
    });
  }


  resetForm() {
    this.dynamicCampaignForm.reset();
    this.fileList = [];
    this.dynamicPreviewFormData = null;
    this.previewData = null;
    this.dynamicCampaignForm.patchValue({
      serviceType: 'trans',
      serviceSubType: 'service-implicit',
      convertShortUrl: "N",
      msgType: "plain",
      fileType: 'plain',
      scheduleMessage: 'no',
      splitFile: 'no',
      msgLength:this.msgTextLength = this.dynamicCampaignForm.get('msgText')?.value,
      loggedInUserName: this.storageService.username,
    });
    this.genCampaignName();
  }

  genCampaignName() {
    this.dynamicCampaignForm.patchValue({
      campaignName: this.campaignService.genCampaignName()
    })
  }

  // Api Calls

  getSenderIdsByMsgType(type: string = this.messageType, subType: string = 'service-implicit') {
    let sub = type === 'others' ? subType : '';
    this.templateService.getSenderIdByMsgType(type, sub).subscribe(data => {
      this.dynamicCampaignForm.get('senderId')?.reset?.();
      this.listOfSenderIds = data.data.senderIdList;
    })
  }

  getTemplates(type: string, senderId: any) {
    let params = { "loggedInUserName": this.storageService.username,
     "messageSubType": (this.messageType=='others')?this.dynamicCampaignForm.get('serviceSubType')?.value:'',
      "messageType": (this.messageType=='others')?'service':this.messageType,
       "senderId": senderId };
    this.campaignService.getTemplatesBySenderId(params).subscribe(data => {
      this.listOfTemplates = data.data.contentTemplateList;
    })
  }

  getActiveDomains() {
    this.reportService.getActiveDomain().subscribe((data: any) => {
      this.listActiveHost = data?.data.hostNameList;
    })
  }

  // Model Events

  handleOk() {
    this.isOkLoading = true;
    this.submitDynamicCampaign();
  }

  handleCancel() {
    this.isVisible = false;
  }

  // File Upload
  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };

  // Split Array

  get splitPart() {
    return this.dynamicCampaignForm.controls["splitPart"] as FormArray;
  }

  splitArray(): FormGroup {
    return this.fb.group({
      id: '',
      from: '',
      to: '',
      hh: '',
      mm: '',
    })
  }

  addSplitArray() {
    this.splitPart.push(this.splitArray());
  }

  removeSplitArray(i: number) {
    this.splitPart.removeAt(i);
  }

  changeImage(newImageUrl: string) {
    this.imageUrl = newImageUrl;
  }

}
