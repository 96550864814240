import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AnimationBuilder } from '@angular/animations';
import { Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment.webtool';
import { UserService } from 'src/app/sevices/user.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ReportService } from 'src/app/services/reports/report.service';
import { UtilsService } from 'src/app/services/utils/utils.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
  profileForm!:FormGroup
  validateForm = this.fb.group({
    oldPassword: ['', [Validators.required]],
    newPassword: ['', [Validators.required]],
  });
  selected: boolean=false;
  profileModule: any;
  profileData={};
  passwordData={};
  emailData={};
  mobileData={};
  OrganisationData={};
  DepartmentData={};
  isVisible = false;
  passwordVisibleOld = false;
  newPasswordVisible = false;

  //  parameters = {
   
  //   profileForm1: '',
  //     Username: '',
  //     Password: '' ,
  //     Email: '',
  //     mobileNumber:    '',
  //     Organisation: '',
  //     Department:   '',
  //     }

 
constructor( private _httpClient: HttpClient, private fb: FormBuilder,private reportService:ReportService , private utilService: UtilsService,private _storageService: StorageService,

  private _builder: AnimationBuilder ) {
  this.profileForm = fb.group({    
    profileForm1: ['', [Validators.required]],
    Username: ['',[Validators.required]], 
    password: ['',[Validators.required]] ,
    Email :       ['',[Validators.required]],
    mobileNumber:      ['',[Validators.required]],
    Organisation:  ['',[Validators.required]],
    Department:       ['',[Validators.required]],
  })

}
ngOnInit(): void {


    this.reportService.profile().subscribe(data => {
      this.profileData=data.data.user.username
      console.log(this.profileData)
   
      this.passwordData=data.data.user.password
      console.log(this.passwordData)
   
      this.emailData=data.data.user.emailID
      console.log(this.emailData)
   
      this.mobileData=data.data.user.mobileNumber
      console.log(this.mobileData)
   
      
      this.OrganisationData=data.data.user.organization
      console.log(this.OrganisationData)
   
      this.DepartmentData=data.data.user.department
      console.log(this.DepartmentData)
   
  
    })
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    if (this.validateForm.valid) {
      const payload = {
        oldPassword: this.validateForm.value.oldPassword,
        newPassword: this.validateForm.value.newPassword,
        loggedInUserName: this._storageService.username 
      };
      console.log("This is payload for update password: " + JSON.stringify(payload));
      this.reportService.updatedPassword(payload).subscribe(
        (response: any) => {
          console.log('Password updated successfully:', response);
          this.utilService.showSuccessMsg('Password updated successfully');
        },
        (error: any) => {
          console.error('Error updating password:', error);
          this.utilService.showErrorMsg('Failed to update password');
        }
      );
      console.log('Button ok clicked!');
      console.log(this.validateForm.value);
      this.isVisible = false;
      this.validateForm.reset();
    } else {
      this.utilService.showErrorMsg('Please fill out the form correctly.');
    }
  }
  
  

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }


}




