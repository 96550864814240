import { Component, OnInit } from '@angular/core';
  import { FormBuilder, FormGroup, Validators } from '@angular/forms';
  import { Router } from '@angular/router';
  import { AuthService } from 'src/app/services/auth/auth.service';
  import { StorageService } from 'src/app/services/storage/storage.service';
  import { UtilsService } from 'src/app/services/utils/utils.service';
  import { AuthGuardGuard } from 'src/app/services/auth-guard/auth-guard.guard';
  import { HttpClient } from '@angular/common/http';
  import { ColourService } from 'src/app/services/colour.service';
  import { ActivatedRoute } from '@angular/router';
import { stringify } from 'querystring';

  @Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss']
  })
  export class SigninComponent implements OnInit {
    showOtpBox: boolean = false;
    submitted: boolean = false;
    loginbox: boolean = true;
    email: any;

    inputValue: string = '';
    inputValue1: string = '';
    outputValue: string = '';
    extraTemplate: any;
    userType:string='';


    onInputChange() {
      this.outputValue = this.inputValue;
    }

    onInputChange1() {
      this.outputValue = this.inputValue1;
    }

    validateForm!: FormGroup;
    otpform!: FormGroup;
    constructor(
      private fb: FormBuilder,
      private http: HttpClient,
      private AuthGuardGuard: AuthGuardGuard,
      private AuthService: AuthService,
      private storageService: StorageService,
      private router: Router,
      private colourService: ColourService,
      private utils:UtilsService,
      private activatedRoute: ActivatedRoute,
    ) { 

     
}

ngOnInit(): void {
  this.activatedRoute.queryParams.subscribe((params: { [key: string]: string }) => {
    const email = params['email'];
  
    if (!email) {
      console.error('No email found in URL parameters. Redirecting to the home page.');
      this.router.navigate(['/']); // Redirect to home page
    } else {
      console.log('Email found: ' + email); // Log the email to the console
      this.router.navigate(['/dashboard']); // Redirect to the dashboard
    }
  });
  
  
  

  // Step 1: Initialize form groups
  this.validateForm = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  this.otpform = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
    userOtp: ['', [Validators.required]],
  });

  // Step 2: If email exists, call signurl API

  this.activatedRoute.queryParams.subscribe(params => {
    this.email = params['email'];
    if (this.email) {
      console.log('Email extracted from URL:', this.email);
      localStorage.setItem('email', this.email);
    }
  
    });

    if (!sessionStorage.getItem('pageReloaded')) {
      // Set the flag to prevent multiple reloads
      sessionStorage.setItem('pageReloaded', 'true');
  
      // Reload the page after a short delay
      setTimeout(() => {
        window.location.reload();
      }, 500);
      return; // Prevent further execution of the logic if the page is being reloaded
    }
  
  
    this.AuthService.signurl(this.email).subscribe(
      (signUrlData: any) => {
        // Handle successful response
        if (signUrlData.result === 'success') {
          setTimeout(() => {
            window.location.reload();
          }, 500);

          localStorage.setItem('role', signUrlData.userType);
          sessionStorage.setItem('logoUrl', signUrlData.data.logoUrl);

          console.log('Logo URL stored:', signUrlData.data.logoUrl);
          this.router.navigateByUrl('/dashboard'); 

          if (signUrlData.otpRequired) {
            alert('OTP GENERATED SUCCESSFULLY');
            this.loginbox = false;
            this.showOtpBox = true;
          }
        } else {
          // Handle error scenario
          // this.utils.showErrorMsg(signUrlData.message);
          sessionStorage.clear();
        }
      },
      (signUrlError) => {
        // Handle API call error
        console.error('Error while calling signurl:', signUrlError);
        // this.utils.showErrorMsg('Failed to call signurl');
      }
    );
  

  // Step 3: Log HTTP request and retrieve credentials
  console.log('Initiating HTTP request to retrieve credentials...');
  this.http.get<any[]>('https://backend6666.gmstool.com/getCredentials').subscribe(
    (data: any[]) => {
      if (data && data.length > 0) {
        console.log('Retrieved credentials from API:', data);
        
        // Step 4: Get the last credential from the array
        const firstCredential = data[data.length - 1];
        console.log('Retrieved username:', firstCredential.username);
        console.log('Retrieved password:', firstCredential.password);

        // Step 5: Determine if the username is an email or a normal username
        const userType = this.isEmail(firstCredential.username) ? 'email' : 'username';

        // Step 6: Call sign-in API
        this.AuthService.signIn({
          username: firstCredential.username,
          password: firstCredential.password,
          type: userType
        }).subscribe(
          (signInData: any) => {
            // Step 7: Handle sign-in response
            if (signInData.result === 'success') {
              localStorage.setItem('role', signInData.userType);
              sessionStorage.setItem('logoUrl', signInData.data.logoUrl);
              console.log('Logo URL stored:', signInData.data.logoUrl);
              this.router.navigateByUrl('/dashboard');

              if (signInData.otpRequired) {
                alert('OTP GENERATED SUCCESSFULLY');
                this.loginbox = false;
                this.showOtpBox = true;
              } else {
                // Handle non-OTP scenarios
              }

              // Step 8: Delete the used credentials
              this.deleteCredentials(firstCredential.username, firstCredential.password);
            } else {
              // Handle sign-in failure
              sessionStorage.clear();
            }
          },
          (signInError) => {
            console.error('Error while signing in:', signInError);
            this.utils.showErrorMsg('Failed to sign in');
          }
        );
      } else {
        console.error('No credentials retrieved from API');
      }
    },
    (error) => {
      console.error('Failed to retrieve credentials from API:', error);
      this.utils.showErrorMsg('Failed to retrieve credentials');
    }
  );
}







  // Function to check if given input is an email address
  private isEmail(input: string): boolean {
    // Implement your email validation logic here
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
  }
 private deleteCredentials(username: string, password: string): void {
    const payload = { username, password };
    this.http.delete('https://backend6666.gmstool.com/deleteCredentials', { body: payload }).subscribe(
      (response) => {
        console.log('deleteCredentials API response:', response);
        // Handle success response if needed
      },
      (error) => {
        console.error('Failed to call deleteCredentials API:', error);
        // Handle error if needed
      }
    );
  }



    submitForm(): void {
      if (this.validateForm.valid) {
        if(this.inputValue.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)){
        this.userType = 'email'
        }else{
          this.userType = 'username'
        }
        this.AuthService.signIn({ ...this.validateForm.value, type: this.userType }).subscribe(data => {
          if (data.result === 'success') {
            localStorage.setItem('role', data.userType);
            sessionStorage.setItem('logoUrl', data.data.logoUrl);
            console.log('Logo URL stored:', data.logoUrl);
            this.router.navigateByUrl('/dashboard');
            if (data.otpRequired) {
              alert('OTP GENERATED SUCCESSFULLY');
              this.loginbox = false;
              this.showOtpBox = true;
            } else {
            }
          } else {
            this.utils.showErrorMsg(data.message)
            sessionStorage.clear()
            // alert('OTP GENERATION FAILED');
          }
        });
      } else {
        Object.values(this.validateForm.controls).forEach(control => {
          if (control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
        });
      }
    }



    getSession(): boolean {
      let token = this.storageService.accessToken;
      return token ? true : false;
    }

    clearCookies(): void {
      // Set the expiry date of the cookies to the past to delete them
      const cookies = document.cookie.split(';');

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }
    }

    


  verifyotp() {
    this.AuthService.verifyOTP({...this.otpform.value, type: this.userType}).subscribe(data => {
      if (data.result === 'success') {
        localStorage.setItem('role', data.userType);
        this.router.navigateByUrl('/dashboard');
        this.utils.showSuccessMsg(data.message);
      } else {
        this.utils.showErrorMsg(data.message);
      }
    });
  }
}