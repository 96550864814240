import { Component, OnInit, Injector, ViewChild, ViewContainerRef    } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreditCountPipe } from 'src/app/pipe/credit-count.pipe';
import { CampaignService } from 'src/app/services/campaign/campaign.service';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { TemplateService } from 'src/app/services/templates/template.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { CAMPAIGN_NAME_HINT, CAMPAIGN_NAME_REGEX, PLAIN_TXT_REGEX } from 'src/environments/environment';
import { CreateUrlComponent } from '../../short-url/create-url/create-url.component';

@Component({
  selector: 'app-quick-campaign',
  templateUrl: './quick-campaign.component.html',
  styleUrls: ['./quick-campaign.component.scss']
})
export class QuickCampaignComponent implements OnInit {

  @ViewChild('dynamicContent', { read: ViewContainerRef, static: false }) dynamicContent!: ViewContainerRef;

  // someValue: string = 'Initial value';
  quickCampaignForm = this.fb.group({
    senderId: [null, [Validators.required]],
    entityId: ['', Validators.required],
    loggedInUserName: this.storageService.username,
    campaignName: [null, [Validators.required, Validators.pattern(CAMPAIGN_NAME_REGEX)]],
    mobileNumber: ['', Validators.required],
    serviceType: 'trans',
    serviceSubType: 'service-implicit',
    dltTemplateId: ['', [Validators.required]],
    isShortUrlSelected: "N",
    msgPart: "single",
    msgText: ['', [Validators.required]],
    msgType: "plain",
    perMsgCredit: "",
    shortUrlName: [null],
    templateName: [''],
  });
  messageType: string = 'trans';
  note: string = CAMPAIGN_NAME_HINT;
  templateSelected: any;
  auto: any;
  listOfSenderIds: any;
  listOfTemplates: any;
  listOfShortUrl: any;
  senderIdSelected: any;
  totalMobileNumbers: any;
  isShortUrlSelected: string = 'N';
  messageText: string = '';
  listOfURLSelected: string[] = [];
  viewshortUrl!: FormGroup; 
  isVisible: boolean = false;
  isOkLoading: boolean = false;
  msgTextLength:number=0;
  visible = false;
  shortCode: string = '';
  listActiveHost: any;


  constructor(
    private fb: FormBuilder,
    private storageService: StorageService,
    private templateService: TemplateService,
    private campaignService: CampaignService,
    private reportService: ReportService,
    private utilService: UtilsService,
    private creditCountPipe: CreditCountPipe,
    private injector: Injector
  ) { 

     
  }

  ngOnInit(): void {

    this.getActiveDomains();
    this.getAllShortUrl();


    this.viewshortUrl = this.fb.group({
      callbackUrl: ["http://"],
      callbackUrlValue:"",
      domainName: ["", Validators.required],
      isActive: "Y",
      isDynamic: "N",
      shortCode: '',
      loggedInUserName: this.storageService.username,
      longUrl: "http://",
      longUrlValue: ["", Validators.required],
      name: ["", Validators.required],
    });

    


    this.getSenderIdsByMsgType();
    this.getAllShortUrl();
    this.genCampaignName();
  }

  lengthWithoutNewlines(str: string) {
    return str.replace(/\r/g, '').replace(/\n/g, '').length;
  }
  msgLength(){
    var sectionToCheck = this.quickCampaignForm.get('msgText')?.value;
   var allFoundCharacters = sectionToCheck.match(/[\[\]\{\}\\|₹\^€\~]/g);
   var splCharCount = (allFoundCharacters) ? allFoundCharacters.length : 0;
   var charCount = this.lengthWithoutNewlines(this.quickCampaignForm.get('msgText')?.value) + parseInt(splCharCount);
   this.msgTextLength = charCount;
 }


  mobileNumberInputChange(event: any) {
    this.totalMobileNumbers = this.campaignService.getMobileNumberCount(event);
  }

  onItemSelect(shortname: any, field: any) {
    var cursorPos = this.campaignService.getCaretPos(field);
    var msgText = this.quickCampaignForm.value.msgText;
    var textBefore = msgText.substring(0, cursorPos);
    var textAfter = msgText.substring(cursorPos, msgText.length);
    var shortObj = this.listOfShortUrl.filter((p: any) => p.name == shortname)[0];
    this.quickCampaignForm.patchValue({
      msgText: textBefore + ""   + textAfter + shortObj.hostName+"/"+this.senderIdSelected+ "/xxxxxx"
    // msgText: textBefore + shortObj.hostName +"/"+this.senderIdSelected+  "/xxxxxx" 

   
  });
  console.log("look here senderid show or not"+msgText)
  }

  removeShortUrlInMsgText(value: any) {
    // Get the current message text
    let msgText = this.quickCampaignForm.value.msgText;
  
    // Find the unselected short URL and its details
    let unSelectData = value;
    let shortObj = this.listOfShortUrl.find((p: any) => p.name === unSelectData);
  
    if (!shortObj) {
      console.error('Short URL object not found for:', unSelectData);
      return;
    }
  
    console.log('Short URL Object:', shortObj);
  
    // Construct the text pattern to be removed
    let finding_text = `${shortObj.hostName}/${this.senderIdSelected}/xxxxxx`;
    console.log('Text to find:', finding_text);
  
    // Ensure the finding text exists in the current msgText
    let index = msgText.indexOf(finding_text);
    if (index === -1) {
      console.warn('Text not found in msgText:', finding_text);
      return;
    }
  
    console.log('Text found at index:', index);
  
    // Remove the finding text from msgText
    let new_text = msgText.slice(0, index) + msgText.slice(index + finding_text.length);
  
    // Log and patch the updated text
    console.log('Updated msgText:', new_text);
    this.quickCampaignForm.patchValue({
      msgText: new_text.trim() // Trim to avoid accidental extra spaces
    });
  
    // Remove the unselected URL from the selected list
    let unSelectData_index = this.listOfURLSelected.findIndex((item: any) => item === unSelectData);
    if (unSelectData_index > -1) {
      this.listOfURLSelected.splice(unSelectData_index, 1);
    }
  
    console.log('Updated listOfURLSelected:', this.listOfURLSelected);
  }
  

  onItemDeSelect(event: any) {
    this.removeShortUrlInMsgText(event)
  }



  submitForm() {
    if (this.quickCampaignForm.valid) {
      let rawData = this.quickCampaignForm.getRawValue();
      if (this.quickCampaignForm.valid) {
        if (this.campaignService.validateCampaignForm(rawData)) {
          // this.submitQuickCampaign();
          this.isVisible = true;
        }
      }
    } else {
      Object.values(this.quickCampaignForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  submitQuickCampaign() {
    let params = this.quickCampaignForm.getRawValue();

    const senderIdPattern = `/${this.senderIdSelected}/`; 
    params.msgText = params.msgText.replace(senderIdPattern, '/');

    params.perMsgCredit = this.creditCountPipe.transform(params.msgText);
    params.contentTemplateId = params.templateName.templateId;
    delete params.templateName;
    params.mobileNumber = params.mobileNumber.replaceAll('\n', '\r\n');
    if (params.serviceType !== "service") {
      delete params.serviceSubType;
    }
    this.campaignService.sendQuickSMS(params).subscribe(data => {
      this.resetForm();
      this.isVisible = false;
      this.isOkLoading = false;
      if (data.result === "Success") {
        this.utilService.showSuccessMsg(data.message);
      } else {
        this.utilService.showErrorMsg(data.message);
      }
    }, err => {
      this.utilService.showErrorMsg('Something Went Wrong');
    });
  }




  resetForm() {
    this.totalMobileNumbers = 0;
    this.quickCampaignForm.reset()
    this.quickCampaignForm.patchValue({
      serviceType: 'trans',
      serviceSubType: 'service-implicit',
      isShortUrlSelected: "N",
      msgPart: "single",
      msgType: "plain",
      msgLength:
      this.msgTextLength = this.quickCampaignForm.get('msgText')?.value,
      loggedInUserName: this.storageService.username,
    });
    this.genCampaignName();
  }

  genCampaignName() {
    this.quickCampaignForm.patchValue({
      campaignName: this.campaignService.genCampaignName()
    })
  }

  // Api Calls

 

  getSenderIdsByMsgType(type: string = this.messageType, subType: string = 'service-implicit') {
    let sub = type === 'others' ? subType : '';
    if(type === 'others'){
      console.log(sub)
    }
    this.templateService.getSenderIdByMsgType(type, sub).subscribe(data => {
      this.quickCampaignForm.get('senderId')?.reset?.();
      this.listOfTemplates = [];
      this.listOfSenderIds = data.data.senderIdList;
    })
  }

  getTemplates(type: string, senderId: any) {
    let params = { "loggedInUserName": this.storageService.username, "messageSubType": (this.messageType=='others')?this.quickCampaignForm.get('serviceSubType')?.value:'', "messageType": (this.messageType=='others')?'service':this.messageType, "senderId": senderId };
    this.campaignService.getTemplatesBySenderId(params).subscribe(data => {
      this.listOfTemplates = data.data.contentTemplateList;
    })
  }


  // Model Events

  handleOk() {
    this.isOkLoading = true;
    this.submitQuickCampaign();
  }

  handleCancel() {
    this.isVisible = false;
  }

open(){
  this.visible = true;
}
  

close(){
  this.visible = false;
}

getActiveDomains() {
  this.reportService.getActiveDomain().subscribe((data: any) => {
    this.listActiveHost = data?.data.hostNameList;
  })
}

deleteShortUrl(data: any) {
  let params = { "loggedInUserName": "airteltesting", "shortUrlId": data.shortUrlId, "operation": "removeShortUrlFromList" };
  this.reportService.deleteShortUrl(params).subscribe(data => {
    this.getAllShortUrl();
    if (data.result === "Success") {
      this.utilService.showSuccessMsg(data.message);
    } else {
      this.utilService.showErrorMsg(data.message);
    }
  })
}

getAllShortUrl() {
  this.reportService.listAllShortUrl().subscribe(data => {
    this.listOfShortUrl = data.data.shortUrlList;
  })
}

submitshorturl() {
  let formData = this.viewshortUrl.getRawValue();

  if (formData.callbackUrlValue !== '') {
    formData.callbackUrl = formData.callbackUrl + this.viewshortUrl.get('callbackUrlValue')?.value;
  } else {
    formData.callbackUrl = "";
  }

  if (formData.longUrlValue !== "") {
    formData.longUrl = formData.longUrl + formData.longUrlValue;
  }

  delete formData.callbackUrlValue;
  delete formData.longUrlValue;
  delete formData.shortCode;

  this.reportService.addviewshortUrl(formData).subscribe(
    data => {
      if (data.result === "Success") {
        this.visible = false;
        this.utilService.showSuccessMsg(data.message);
        this.getAllShortUrl();

        // Automatically select the new short URL name
        if (data.data && data.data.shortUrlName) {
          this.viewshortUrl.patchValue({ shortUrlName: [data.data.shortUrlName] });
        }

        // Fetch all short URLs and log the new short URL name
        this.reportService.listAllShortUrl().subscribe(data => {
          // Store the list of short URLs
          this.listOfShortUrl = data.data.shortUrlList;
        
          // Find and log the newly created short URL by formData.shortUrlName
          const newShortUrl = this.listOfShortUrl.find(
            (url: any) => url.name === formData.shortUrlName
          );
          if (newShortUrl) {
            console.log('New Short URL Found:', newShortUrl);
          } else {
            console.log('No match found for the new short URL name:', formData.shortUrlName);
          }
        
          // Search for a specific name ("atulya2")
          const searchName = formData.name;
          const matchedItem = this.listOfShortUrl.find((item: { name: string }) => item.name === searchName);
          if (matchedItem) {
            console.log('Matched Item:', matchedItem);
            this.quickCampaignForm.patchValue({
              shortUrlName: [matchedItem.name]
            });
            // Use matchedItem for further processing
          } else {
            console.log('No match found for the name:', searchName);
          }
        });
        
      } else {
        this.utilService.showErrorMsg(data.message);
        this.shortCode = data.data?.shortCode;
      }
    },
    err => {
      this.utilService.showErrorMsg('Something Went Wrong');
    }
  );
}
onShortUrlSelect(event: string[], field: any) {
  if (this.listOfURLSelected.length < event.length) {
    const newItem = event.filter((x: any) => !this.listOfURLSelected.includes(x))[0];
    console.log("newItem", newItem);
    this.listOfURLSelected = event;
    this.onItemSelect(newItem, field);
  } else {
    const removedItem = this.listOfURLSelected.filter((x: any) => !event.includes(x))[0];
    console.log("removedItem", removedItem);
    this.listOfURLSelected = this.listOfURLSelected.filter((x: any) => x !== removedItem);
    this.removeShortUrlInMsgText(removedItem);
  }

  // Handle the selection of the last item in the list (if any)
  const matchedItem = this.listOfShortUrl.find((item: { name: string }) => item.name === event[event.length - 1]);
  if (matchedItem) {
    console.log('Matched Item:', matchedItem);

    // Patch the form control with the selected short URL
    this.viewshortUrl.patchValue({
      shortUrlName: [matchedItem.name]
    });
  } else {
    console.log('No match found for the name:', event[event.length - 1]);
  }
}



  
}

