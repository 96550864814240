import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LeftNavComponent } from './components/left-nav/left-nav.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PagesModule } from './pages/pages.module';
import { CreditsCardComponent } from './components/credits-card/credits-card.component';
import { HeaderLogoComponent } from './components/header-logo/header-logo.component';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { HeaderComponent } from './components/header/header.component';
import { ChartsComponent } from './components/charts/charts.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NzTableModule } from 'ng-zorro-antd/table';
import { SimpleTableComponent } from './components/simple-table/simple-table.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { MatTabsModule } from '@angular/material/tabs';

import { SigninComponent } from './pages/signin/signin.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { AuthInterceptor } from './services/auth-guard/auth.interceptor';
import { SummaryReportComponent } from './pages/reports/summary-report/summary-report.component';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { SenderidReportComponent } from './pages/reports/senderid-report/senderid-report.component';
import { DetailedReportComponent } from './pages/reports/detailed-report/detailed-report.component';
import { ClickerComponent } from './pages/reports/clicker/clicker.component';
import { DownloadReportComponent } from './pages/reports/download-report/download-report.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { CreditHistoryComponent } from './pages/credit-history/credit-history.component';
import { AddViewSenderidComponent } from './pages/add-view-senderid/add-view-senderid.component';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { ContentTemplateMgmtComponent } from './pages/content-template-mgmt/content-template-mgmt.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { AddContentTemplateComponent } from './pages/content-template-mgmt/add-content-template/add-content-template.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { ViewTemplatesComponent } from './pages/content-template-mgmt/view-templates/view-templates.component';
import { UploadTemplateComponent } from './pages/content-template-mgmt/upload-template/upload-template.component';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { PhonebookComponent } from './pages/phonebook/phonebook.component';
import { GroupsComponent } from './pages/phonebook/groups/groups.component';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { ContactsComponent } from './pages/phonebook/contacts/contacts.component';
import { ViewContactsComponent } from './pages/phonebook/view-contacts/view-contacts.component';
import { BlacklistComponent } from './pages/phonebook/blacklist/blacklist.component';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { LeftNavWebtoolComponent } from './components/left-nav-webtool/left-nav-webtool.component';
import { CampaignidReportComponent } from './pages/reports/campaignid-report/campaignid-report.component';
import { ClickerReportComponent } from './pages/reports/clicker-report/clicker-report.component';
import { ShortUrlComponent } from './pages/short-url/short-url.component';
import { DomainManagerComponent } from './pages/domain-manager/domain-manager.component';
import { ScheduledCampaignComponent } from './pages/campaign-mgmt/scheduled-campaign/scheduled-campaign.component';
import { QuickCampaignComponent } from './pages/campaign-mgmt/quick-campaign/quick-campaign.component';
import { CreditCountPipe } from './pipe/credit-count.pipe';
import { UploadCampaignComponent } from './pages/campaign-mgmt/upload-campaign/upload-campaign.component';
import { DynamicCampaignComponent } from './pages/campaign-mgmt/dynamic-campaign/dynamic-campaign.component';
import { GroupCampaignComponent } from './pages/campaign-mgmt/group-campaign/group-campaign.component';
import { ViewShortComponent } from './pages/short-url/view-short/view-short.component';
import { CreateUrlComponent } from './pages/short-url/create-url/create-url.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DocumentationComponent } from './components/documentation/documentation.component';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import { NzCardModule } from 'ng-zorro-antd/card';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';
import { MatInputModule } from '@angular/material/input';
import { NzI18nModule } from 'ng-zorro-antd/i18n';
import { DataService } from './pages/content-template-mgmt/data-service.service';
import { EditModalComponent } from './pages/content-template-mgmt/edit-modal/edit-modal.component';
import { ReRunComponent } from './pages/re-run/re-run.component';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';

// import { ToastrModule } from 'ngx-toastr';

function loadTheme(http: HttpClient) {
  return () => {
    return http.get<{ dashboardGradient: string }>('assets/colors.json')
      .toPromise()
      .then((config) => {
        // Check if config is defined and has the expected property
        if (config && config.dashboardGradient) {
          // Set the CSS variable on the root element
          document.documentElement.style.setProperty('--dashboard-gradient', config.dashboardGradient);
        }
      })
      .catch(() => {
        console.warn('Failed to load theme configuration. Using default values.');
      });
  };
}







const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};

const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

@NgModule({
  declarations: [
    AppComponent,
    LeftNavComponent,
    DashboardComponent,
    CreditsCardComponent,
    HeaderLogoComponent,
    HeaderComponent,
    ChartsComponent,
    SimpleTableComponent,
    SigninComponent,
    SummaryReportComponent,
    SenderidReportComponent,
    DetailedReportComponent,
    DownloadReportComponent,
    DocumentationComponent,
    CreditHistoryComponent,
    AddViewSenderidComponent,
    ContentTemplateMgmtComponent,
    AddContentTemplateComponent,
    ViewTemplatesComponent,
    UploadTemplateComponent,
    PhonebookComponent,
    GroupsComponent,
    ContactsComponent,
    ViewContactsComponent,
    BlacklistComponent,
    LeftNavWebtoolComponent,
    CampaignidReportComponent,
    ClickerReportComponent,
    ShortUrlComponent,
    CreateUrlComponent,
    ViewShortComponent,
    DomainManagerComponent,
    ScheduledCampaignComponent,
    QuickCampaignComponent,
    CreditCountPipe,
    UploadCampaignComponent,
    DynamicCampaignComponent,
    GroupCampaignComponent,
    ProfileComponent,
    ClickerComponent,
    EditModalComponent,
    ReRunComponent,

  ],
  imports: [
    BrowserModule,
   NzDrawerModule,
    AppRoutingModule,
    NzDrawerModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgApexchartsModule,
    NzLayoutModule,
    NzBreadCrumbModule,
    NzMenuModule,
    NzButtonModule,
    NzGridModule,
    NzInputNumberModule,
    NzFormModule,
    NzInputModule,
    MatSelectModule,
    MatRadioModule,
    NzTimePickerModule,
    MatIconModule,
    MatTabsModule,
    MatInputModule,
    NzCardModule,
    NzI18nModule,
    // NzIconModule,
    NzTypographyModule,
    NzSpaceModule,
    NzToolTipModule,
    NzPageHeaderModule,
    NzAvatarModule,
    NzTableModule,
    NzDividerModule,
    NzDropDownModule,
    NzSegmentedModule,
    NzPaginationModule,
    NzDatePickerModule,
    NzSelectModule,
    NzTagModule,
    NzMessageModule,
    NzRadioModule,
    NzTabsModule,
    NzModalModule,
    // ToastrModule,
    NzUploadModule,
    NzPopconfirmModule,
    NzDescriptionsModule,
    NzProgressModule,
    // PagesModule,
    NzIconModule.forChild(icons),
  ],
  exports: [
    CreditCountPipe
  ],
  providers: [
    CreditCountPipe,
    DataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: NZ_I18N, useValue: en_US }],
  bootstrap: [AppComponent]
})

export class AppModule { }
