import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ColourService } from 'src/app/services/colour.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  isWalletEnabled!: string;
  // availableCredits: number = 0;
  // availableBalance: number = 0;
  isSmallScreen = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenSize();
  }
  totalSubmited: number[] = [];
  totalDeliverd: number[] = [];
  summaryHour: number[] = [];

  totalDelivered: number = 0;
  totalFailed: number = 0;
  totalSubmit: number = 0;
  calculatedResult: number = 0;

  // latencyReport: any[] = ['0', '0', '0', '0', '3', '5'];

  latencyReport: any;
  username:any

  graphOptions = ['Hourly', 'Weekly', 'Monthly'];

  lineChartOptions: any;
  pieChart: any;

  listOfColumn = [
    {
      title: 'Summary Date',
      compare: (a: any, b: any) => a.summaryDate.localeCompare(b.summaryDate),
      priority: 1
    },
    {
      title: 'Total Request',
      compare: (a: any, b: any) => a.totalRequest-b.totalRequest,
      priority: false
    },
    {
      title: 'Total Rejected',
      compare: (a: any, b: any) => a.totalRejected-b.totalRejected,
      priority: 3
    },
    {
      title: 'Total Submit',
      compare: (a: any, b: any) => a.totalSubmit-b.totalSubmit,
      priority: 4
    },
    {
      title: 'Total Delivered',
      compare: (a: any, b: any) => a.totalDelivered-b.totalDelivered,
      priority: 5
    }, {
      title: 'Total Failed',
      compare: (a: any, b: any) => a.totalFailed-b.totalFailed,
      priority: 6
    }, {
      title: 'Total Awaited',
      compare: (a: any, b: any) => a.totalAwaited-b.totalAwaited,
      priority: 7
    }
  ];


  listOfData: any;

  summaryReportData: any;
  constructor(private route: ActivatedRoute,
    private storageService: StorageService,
    private reportService: ReportService,
    private dashboardService:DashboardService,
    private colourService: ColourService,
    private utils: UtilsService) { 
      
     }


     


  ngOnInit(): void {
    this.getSummaryReport();
    this.getSummaryReport(true);
    this.getHourlyReport();
    // this.getTelcoReport();
    this.getDashboardStats();
    this.username=this.storageService.username;

    this.checkScreenSize()


    this.colourService.getColorConfig().subscribe(config => {
      this.applyColor(config.dashboardGradient);
    });
  }

  // Apply the gradient color to the :root
  private applyColor(dashboardGradient: string): void {
    document.documentElement.style.setProperty('--dashboard-gradient', dashboardGradient);


  }






  getDNAwaitedValue(): number {
    const totalDelivered = this.totalDelivered || 0;
    const totalFailed = this.totalFailed || 0;
    const totalSubmit = this.totalSubmit || 0;
    return (totalDelivered + totalFailed) - totalSubmit;
  }
  
  getDNAwaitedPercentage(): number {
    const dnAwaited = this.getDNAwaitedValue();
    const totalRequest = this.summaryReportData?.totalRequest || 1; 
    return (dnAwaited / totalRequest) * 100;
  }


  checkScreenSize(): void {
    this.isSmallScreen = window.innerWidth <= 768; // Adjust the breakpoint as needed
  }

  ngOnDestroy() {
  }

  getPercentage(value: any, total: any) {
    return isNaN(value * 100 / total) ? '0' : (value * 100 / total).toFixed()
  }

  handleIndexChange(e: any) {
    if (e === 0) {
      this.getHourlyReport();
    } else {
      this.getWeeklyMonthlyReport(e === 2 ? true : false)
    }
  }

  // getTelcoReport() {
  //   const params = {
  //     "fromDate": this.utils.formatDate(this.utils.getToday()),
  //     "toDate": this.utils.formatDate(this.utils.getToday()),
  //     "loggedInUserName": this.storageService.username,
  //     "messageId": "", "mobileNumber": "", "pageNumber": "nopagination", "senderId": ""
  //   }

  //   this.reportService.getTelcoReport(params).subscribe(data => {
  //     this.latencyReport = data.data.latencyReport;
  //     this.populatePieChart();
  //   });
  // }

  // Method to calculate DN Awaited
getDnAwaited(): number {
  if (this.summaryReportData) {
    const totalDelivered = this.summaryReportData.totalDelivered || 0;
    const totalFailed = this.summaryReportData.totalFailed || 0;
    const totalSubmit = this.summaryReportData.totalSubmit || 0;
    
    return totalSubmit - (totalDelivered + totalFailed);
  }
  return 0;
}

  getHourlyReport() {

    this.summaryHour = [];
    this.totalSubmited = [];
    this.totalDeliverd = [];

    this.reportService.getHourlyReport().subscribe(hdata => {
      for (var i = 0; i < 24; i++) {
        this.totalSubmited[i] = 0;
        this.totalDeliverd[i] = 0;
        this.summaryHour[i] = i + 1;
      }

      hdata.data.grid.map((data: any) => {
        var summaryHr = data?.summaryHour;
        this.totalSubmited[summaryHr] = parseInt(data.totalSubmit);
        this.totalDeliverd[summaryHr] = parseInt(data.totalDelivered);
      })
      this.populateHourlyGraph();
    })
  }


  availableCredits:number = 0;
  availableBalance:number = 0;
  getDashboardStats() {
    this.dashboardService.getDashboardData().subscribe(data => {
      this.availableCredits = data.data.availableCredits;
      this.availableBalance = data.data.availableBalance;
      this.isWalletEnabled = data.data.isWalletEnabled; 
    })
  }

  getWeeklyMonthlyReport(isMonthly: boolean = false) {

    this.summaryHour = [];
    this.totalSubmited = [];
    this.totalDeliverd = [];

    let params = {
      fromDate: this.utils.formatDate(isMonthly ? this.utils.getLastMonthDate() : this.utils.getLastWeekDate()),
      loggedInUserName: this.storageService.username,
      toDate: this.utils.formatDate(this.utils.getToday()),
    }
    this.reportService.getSummaryReport(params).subscribe(hdata => {

      hdata.data.grid.map((data: any) => {
        this.summaryHour.push(data.summaryDate)
        this.totalSubmited.push(parseInt(data.totalSubmit));
        this.totalDeliverd.push(parseInt(data.totalDelivered));
      })

      this.populateHourlyGraph();
    })
  }

  getSummaryReport(isWeekly: boolean = false) {
    const today = this.utils.getToday();
    const lastWeek = this.utils.getLastWeekDate();
    const params = {
      "loggedInUserName": this.storageService.username,
      "fromDate": this.utils.formatDate(isWeekly ? lastWeek : today),
      "toDate": this.utils.formatDate(today),
    }

    this.reportService.getSummaryReport(params).subscribe(data => {
      if (isWeekly) {
        this.listOfData = data.data.grid.slice(0, 7).reverse();
      } else {
        this.summaryReportData = data.data.grid[0];
      }
    })
  }

  populateHourlyGraph() {
    this.lineChartOptions = {
      series: [{
        name: 'Total Delivery',
        type: 'line',
        data: this.totalDeliverd
      },
      {
        name: 'Total Submit',
        type: 'line',
        data: this.totalSubmited
      }],
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false
        }
      },
      stroke: {
        curve: 'smooth',
      },
      markers: {
        size: 0,
      },
      title: {
        text: 'Overview'
      },
      dataLabels: {
        enabled: false,
        // enabledOnSeries: [1]
      },
      yaxis: {
        title: {
          text: "Total Submit Counts"
        }
      },
      labels: this.summaryHour
      //     xaxis: {
      //   type: 'datetime'
      // }
    };
  }

  populatePieChart() {
    const seriesArray = Object.values<string>(this.latencyReport).map(str => parseInt(str));

    this.pieChart = {
      series: seriesArray,
      labels: Object.keys(this.latencyReport),
      chart: {
        type: 'donut',
      },
      title: {
        text: 'Telco Latency'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    };
  }



}
