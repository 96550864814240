<div class="form-input">
    <form nz-form [formGroup]="addDomain" nzLayout="vertical">
        <div nz-row [nzGutter]="24" nzAlign="middle">
            <div nz-col>
                <nz-form-item>
                    <nz-form-label nzRequired>Title</nz-form-label>
                    <nz-form-control>
                        <input type="text" nz-input formControlName="title" placeholder="Title" />
                    </nz-form-control>
                    <small><i>&nbsp;</i></small>
                </nz-form-item>
            </div>
            <div nz-col>
                <nz-form-item>
                    <nz-form-label >Description</nz-form-label>
                    <nz-form-control>
                        <input type="text" nz-input formControlName="domainName" placeholder="Domain Name" />
                    </nz-form-control>
                    <small><i>To reduce message length enter the domain name without http/https</i></small>
                </nz-form-item>
            </div>
            <div nz-col>
                <nz-form-control>
                    <button nz-button nzType="primary"  (click)="submitForm()">Submit</button>
                    <button nz-button  (click)="resetForm()" style="margin-left: 25px;">Clear</button>
                </nz-form-control>
            </div>
            <div nz-col>
                <nz-form-control>
                   
                </nz-form-control>
            </div>
        </div>
    </form>
</div>

<app-simple-table [listOfData]="listOfData" [listOfColumn]="listOfColumn" [type]="'domain'"
    (deleteTriggred)="deleteDomain($event)" [pagination]="true"></app-simple-table>
