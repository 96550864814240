import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  router: any;
  isAuthenticated(): any {
    throw new Error('Method not implemented.');
  }
  private _authenticated: boolean = false;

  constructor(
    private _httpClient: HttpClient,
    private _storageService: StorageService
  ) {
    window.addEventListener('storage', (event) => {
      if (event.key === 'clearStorageEvent' && event.newValue === 'true') {
        this.clearStorage();
      }
    });
  }

  verifyOTP(params: any): Observable<any> {
    return this._httpClient.post(environment.verifyOTP, params).pipe(
      switchMap((response: any) => {
        if (response.result === 'success') {
          this._storageService.accessToken = response?.authJwtToken?.split(' ')[1];
          this._authenticated = true;
          this._storageService.user = response.data;
          
        }
        return of(response);
      })
    );
  }

  signIn(credentials: any): Observable<any> {
    return this._httpClient.post(environment.loginUrl, credentials).pipe(
      switchMap((response: any) => {
        if (!response.otpRequired && response.result === 'success') {
          this._storageService.accessToken = response?.authJwtToken?.split(' ')[1];
          this._authenticated = true;
          // Store user data along with logoUrl in session storage
          this._storageService.user = {
            ...response.data,
            logoUrl: response.logoUrl
          };
        }
        return of(response);
      })
    );
  }



  signurl(_email: string): Observable<any> {
    // Modify the payload to include the email
    const payload = { email: localStorage.getItem('email') };
    // const  = {  };

   
  
    // Assuming you have the URL endpoint stored in 'environment.Url'
    return this._httpClient.post(environment.Url, payload).pipe(
      switchMap((response: any) => {
        if (!response.otpRequired && response.result === 'success') {
          // Set the access token in the storage service
          this._storageService.accessToken = response?.authJwtToken?.split(' ')[1];
          this._authenticated = true;
          // Store user data along with logoUrl in session storage
          this._storageService.user = {
            ...response.data,
            logoUrl: response.logoUrl
          };
  
          // Assuming 'userName' is present in the API response
          const username = response.username;
  
          // If 'userName' is present in the API response, set it in localStorage
          if (username) {
            localStorage.setItem('username', username);
          }
        }
        return of(response);
      })
    );
  }
  

  signOut(): Observable<any> {
    localStorage.setItem('clearStorageEvent', 'true');
    this.clearStorage();
    this._authenticated = false;
    return of(true);
  }

  clearStorage() {
    this._storageService.clearStorage(); // Implement your clearStorage method in StorageService
    // Clear cookies here if they are set using ngx-cookie-service or other methods
  }
}
