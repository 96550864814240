import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { CreditCountPipe } from 'src/app/pipe/credit-count.pipe';
import { CampaignService } from 'src/app/services/campaign/campaign.service';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { TemplateService } from 'src/app/services/templates/template.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { CAMPAIGN_NAME_HINT, CAMPAIGN_NAME_REGEX, PLAIN_TXT_REGEX } from 'src/environments/environment';
import { DisabledTimeFn, DisabledTimePartial } from 'ng-zorro-antd/date-picker';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';


@Component({
  selector: 'app-upload-campaign',
  templateUrl: './upload-campaign.component.html',
  styleUrls: ['./upload-campaign.component.scss'],

})



export class UploadCampaignComponent implements OnInit {


  timeDefaultValue: Date | undefined;

  selectedDate = new Date();






  hourOption: string[] = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
  minuteOption: string[] = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"];

  uploadCampaignForm = this.fb.group({
    senderId: [null, [Validators.required]],
    entityId: ['', Validators.required],
    username: this.storageService.username,
    campaignName: [null, [Validators.required, Validators.pattern(CAMPAIGN_NAME_REGEX)]],
    serviceType: 'trans',
    serviceSubType: 'service-implicit',
    dltTemplateId: ['', [Validators.required]],
    isShortUrlSelected: "N",
    msgPart: "single",
    msgText: ['', [Validators.required]],
    msgType: "plain",
    perMsgCredit: "",
    shortUrlName: [null],
    templateName: [''],

    fileType: 'txt',
    scheduleMessage: 'no',
    // fileName: ['', [Validators.required]],
    uploadedBulkfileName: [],
    scheduleInfo: { splitPart: [] },
    splitFile: 'no',
    splitPart: this.fb.array([]),
    scheduleDateTime: '',
    scheduleDate: ''
  });

  messageType: string = 'trans';
  note: string = CAMPAIGN_NAME_HINT;
  templateSelected: any;
  listOfSenderIds: any;
  listOfTemplates: any;
  listOfShortUrl: any;
  senderIdSelected: any;
  isShortUrlSelected: string = 'N';
  messageText: string = '';
  listOfURLSelected: string[] = [];
  fileList: any[] = [];
  scheduleMessage: string = 'no';
  splitFile: string = 'no';
  uploading = false;
  fileType: any = 'txt';
  totalMobileNumbers: number = 0;
  scheduleDateTime: any;
  scheduleDate: any;

  isVisible: boolean = false;
  isOkLoading: boolean = false;
  msgTextLength:number=0;
  minuteCounter: number = 0;
  hh:any;
  visible = false;
  shortCode: string = '';
  listActiveHost: any;
  viewshortUrl!: FormGroup; 




  disabledDate = (date: Date): boolean => {
    const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1); // get yesterday's date
  return date < yesterday;
}

  constructor(
    private fb: FormBuilder,
    private storageService: StorageService,
    private templateService: TemplateService,
    private campaignService: CampaignService,
    private reportService: ReportService,
    private utilService: UtilsService,
    private creditCountPipe: CreditCountPipe
  )
  {

    const currentDate = new Date();
    const fifteenMinutesAgo = new Date(currentDate.getTime() + 15 * 60000);
    this.selectedDate = fifteenMinutesAgo;
  }



    disabledDateTime: DisabledTimeFn = (current: Date | null | Date[]) => {
      const today = new Date();
      let currentHour: number | undefined;

      if (current instanceof Array) {
        currentHour = current[0].getHours();
      } else {
        currentHour = current?.getHours();
      }

      const isToday = current instanceof Array ? current[0].getDate() === today.getDate() : current?.getDate() === today.getDate();

      if (!isToday) {
        return undefined;
      }

      return {
        nzDisabledHours: () => {
          if (today.getHours() === 0) {
            return this.range(0, 24).splice(1);
          }
          return this.range(0, 24).splice(0, today.getHours());
        },
        nzDisabledMinutes: () => {
          if (today.getHours() === currentHour) {
            return this.range(0, 60).splice(0, today.getMinutes());
          }
          return [];
        },
        nzDisabledSeconds: () => {
          if (today.getSeconds() === 0) {
            return this.range(0, 60).splice(1);
          }
          return this.range(60, 60).splice(0, today.getSeconds());
        }
      };
    };

    range(start: number, end: number): number[] {
      const result: number[] = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    }

    isHourDisabled(hour: string): boolean {
      const today = new Date();
      const currentHour = today.getHours();
      const selectedDate = this.selectedDate; // Assuming this.selectedDate is the selected date

      if (!selectedDate) {
        return false; // Return false if selectedDate is null or undefined
        }


      // Check if the selected date is today
      if (selectedDate.toDateString() === today.toDateString()) {
        // Convert the selected hour to a number
        const selectedHour = parseInt(hour, 10);


        // Check if the selected hour is less than the current hour
        if (selectedHour < currentHour) {
          return true; // Disable the hour
        }
      }

      return false;
    }




    isMinuteDisabled(minute: string): boolean {
      const today = new Date();
      const currentHour = today.getHours();
      const currentMinute = today.getMinutes();
      const selectedDate = this.selectedDate; // Assuming this.selectedDate is the selected date

      if (!selectedDate) {
        return false; // Return false if selectedDate is null or undefined
        }

      console.log(this.uploadCampaignForm.get('splitPart')?.value[0].hh)
      // Check if the selected date is today
      if (selectedDate.toDateString() === today.toDateString() && currentHour == this.uploadCampaignForm.get('splitPart')?.value[0].hh) {
        // Check if the selected hour is the current hour

        if (parseInt(minute) <= currentMinute ) {

          return true; // Disable the minute for previous minutes in the current hour

        }
        return false;
      }
      return false;


    }








  ngOnInit(): void {


    this.getActiveDomains();
    this.getAllShortUrl();


    this.viewshortUrl = this.fb.group({
      callbackUrl: ["http://"],
      callbackUrlValue:"",
      domainName: ["", Validators.required],
      isActive: "Y",
      isDynamic: "N",
      shortCode: '',
      loggedInUserName: this.storageService.username,
      longUrl: "http://",
      longUrlValue: ["", Validators.required],
      name: ["", Validators.required],
    });


    this.getSenderIdsByMsgType();
    this.getAllShortUrl();
    this.genCampaignName();
    this.addSplitArray();
  }

  lengthWithoutNewlines(str: string) {
    return str.replace(/\r/g, '').replace(/\n/g, '').length;
  }
  msgLength(){
    var sectionToCheck = this.uploadCampaignForm.get('msgText')?.value;
   var allFoundCharacters = sectionToCheck.match(/[\[\]\{\}\\|\^€\~]/g);
   var splCharCount = (allFoundCharacters) ? allFoundCharacters.length : 0;//count
   var charCount = this.lengthWithoutNewlines(this.uploadCampaignForm.get('msgText')?.value) + parseInt(splCharCount);
   this.msgTextLength = charCount;
 }


  handleUpload(): void {
    let files = this.fileList[0]
    if (!files) {
      return
    }
    let uploadedFiletype = files.name.split('.').pop();
    if (this.fileType != uploadedFiletype) {
      this.utilService.showErrorMsg('File Type Mismatch.')
      return
    }

    let formData: FormData = new FormData();
    formData.append('file', files);
    formData.append("userName", this.storageService.username);
    formData.append("fileType", this.fileType);

    this.campaignService.uploadBulkFile(formData).subscribe(data => {
      // this.fileObjResponse = data;
      this.totalMobileNumbers = data.totalNumber;
      this.uploadCampaignForm.patchValue({
        uploadedBulkfileName: data.data.uploadedBulkfileName
      });
      this.utilService.showSuccessMsg(data.message)
    });
  }

  onItemSelect(shortname: any, field: any) {
    var cursorPos = this.campaignService.getCaretPos(field);
    var msgText = this.uploadCampaignForm.value.msgText;
    var textBefore = msgText.substring(0, cursorPos);
    var textAfter = msgText.substring(cursorPos, msgText.length);
    var shortObj = this.listOfShortUrl.filter((p: any) => p.name == shortname)[0];
    this.uploadCampaignForm.patchValue({
      // msgText: textBefore + "" + shortObj.hostName + "/xxxxxx" + textAfter
      msgText: textBefore + ""   + textAfter + shortObj.hostName +"/"+this.senderIdSelected+ "/xxxxxx"
    });
    // this.onMsgTextKeyUp(msgText);
  }

  // removeShortUrlInMsgText(value: any) {
  //   var msgText = this.uploadCampaignForm.value.msgText;
  //   let unSelecteData = value;
  //   var unSelecteData_index = this.listOfURLSelected.findIndex((item: any) => item === unSelecteData);
  //   var shortObj = this.listOfShortUrl.find((p: any) => p.name === unSelecteData);
  //   console.log(shortObj)
  //   let finding_text = shortObj.hostName +"/"+this.senderIdSelected+ "/xxxxxx";
  //   console.log(finding_text)

  //   let index = this.campaignService.getIndex(msgText, finding_text, (unSelecteData_index + 1))
  //   console.log(index)

  //   // if (index === -1) {
  //   // } else {
  //   //   this.listOfURLSelected = [...this.selectedItems]
  //   // }
  //   console.log(msgText)

  //   let new_text = msgText.slice(0, index) + msgText.slice((index + finding_text.length));
  //   console.log(new_text)

  //   this.uploadCampaignForm.patchValue({
  //     msgText: new_text
  //   });
  // }

  removeShortUrlInMsgText(value: any) {
    // Get the current message text
    let msgText = this.uploadCampaignForm.value.msgText;
  
    // Find the unselected short URL and its details
    let unSelectData = value;
    let shortObj = this.listOfShortUrl.find((p: any) => p.name === unSelectData);
  
    if (!shortObj) {
      console.error('Short URL object not found for:', unSelectData);
      return;
    }
  
    console.log('Short URL Object:', shortObj);
  
    // Construct the text pattern to be removed
    let finding_text = `${shortObj.hostName}/${this.senderIdSelected}/xxxxxx`;
    console.log('Text to find:', finding_text);
  
    // Ensure the finding text exists in the current msgText
    let index = msgText.indexOf(finding_text);
    if (index === -1) {
      console.warn('Text not found in msgText:', finding_text);
      return;
    }
  
    console.log('Text found at index:', index);
  
    // Remove the finding text from msgText
    let new_text = msgText.slice(0, index) + msgText.slice(index + finding_text.length);
  
    // Log and patch the updated text
    console.log('Updated msgText:', new_text);
    this.uploadCampaignForm.patchValue({
      msgText: new_text.trim() // Trim to avoid accidental extra spaces
    });
  
    // Remove the unselected URL from the selected list
    let unSelectData_index = this.listOfURLSelected.findIndex((item: any) => item === unSelectData);
    if (unSelectData_index > -1) {
      this.listOfURLSelected.splice(unSelectData_index, 1);
    }
  
    console.log('Updated listOfURLSelected:', this.listOfURLSelected);
  }



  onItemDeSelect(event: any) {
    this.removeShortUrlInMsgText(event)
  }

  // onShortUrlSelect(event: string[], field: any) {
  //   if (this.listOfURLSelected.length < event.length) {
  //     const newItem = event.filter((x: any) => !this.listOfURLSelected.includes(x))[0];
  //     console.log("newItem", newItem)
  //     this.listOfURLSelected = event;
  //     this.onItemSelect(newItem, field);
  //   } else {
  //     const removedItem = this.listOfURLSelected.filter((x: any) => !event.includes(x))[0];
  //     console.log("removedItem", removedItem)
  //     this.listOfURLSelected = this.listOfURLSelected.filter((x: any) => x !== removedItem);
  //     this.removeShortUrlInMsgText(removedItem);
  //   }
  // }

  submitForm() {
    // if (this.uploadCampaignForm.valid) {
      

    let rawData = this.uploadCampaignForm.getRawValue();
    this.hh
    // console.log(rawData.splitPart[0].hh)
    if (this.uploadCampaignForm.valid) {
      if (this.campaignService.validateCampaignForm(rawData)) {
        // this.submitUploadCampaign();
        this.isVisible = true;
      }
    } else {
      Object.values(this.uploadCampaignForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  submitUploadCampaign() {
    if (this.uploadCampaignForm.get('splitPart') && this.uploadCampaignForm.valid) {
      let params = this.uploadCampaignForm.getRawValue();
      const senderIdPattern = `/${this.senderIdSelected}/`; 
      params.msgText = params.msgText.replace(senderIdPattern, '/');
    params.perMsgCredit = this.creditCountPipe.transform(params.msgText);
    // params.contentTemplateId = params.templateName.templateId;
    delete params.templateName;
    delete params.fileType;
    if (params.serviceType !== "service") {
      delete params.serviceSubType;
    }

    if (params.scheduleMessage !== "no") {
      const schDate = params.splitFile === "yes" ? this.scheduleDate : this.scheduleDateTime;
      // console.log(schDate)
      // let schDate = this.utilService.formatDate(params.scheduleDate,'YYYY-MM-DD');
      params.scheduleDate = this.utilService.formatDate(this.selectedDate, 'YYYY-MM-dd');
      // params.scheduleDate = new Date(schDate).toLocaleDateString();
      params.scheduleHour = new Date(this.selectedDate).getHours();
      params.scheduleMinute = new Date(this.selectedDate).getMinutes();
      delete params.scheduleDateTime;
    } else {
      delete params.scheduleDateTime;
      delete params.scheduleDate;
    }

    //scheduleDate
    if (params.splitFile === "yes") {
      const newArr = params.splitPart.map((element: any, index: any) => {
        element.id = (index + 1);
        return element;
      });
      params.scheduleInfo.splitPart = newArr;
    }

    delete params.splitPart;
    console.log('Form field value:', params);

    this.campaignService.sendBulkSMS(params).subscribe(data => {
      this.resetForm();
      this.isVisible = false;
      this.isOkLoading = false;
      console.log('Form value after reset:', this.uploadCampaignForm.value);
      if (data.result === "Success") {
        this.utilService.showSuccessMsg(data.message);
        setTimeout(() => {
          window.location.reload();
        },  1000);
      } else {
        this.utilService.showErrorMsg(data.message);
      }
    }, err => {
      this.utilService.showErrorMsg('Something Went Wrong');
    });
    
  }
  }

  resetForm() {
    this.uploadCampaignForm.reset();
    // this.uploadCampaignForm.setControl('splitPart', this.fb.array([]));
    this.fileList = [];
    this.totalMobileNumbers = 0;
    this.uploadCampaignForm.patchValue({
      serviceType: 'trans',
      serviceSubType: 'service-implicit',
      isShortUrlSelected: "N",
      msgPart: "single",
      msgType: "plain",
      fileType: 'txt',
      scheduleMessage: 'no', // Ensure scheduleMessage is set to 'no'
      scheduleDateTime: null, // Reset scheduleDateTime if necessary
      splitFile: 'no', // Ensure splitFile is set to 'no'
      msgLength:this.msgTextLength = this.uploadCampaignForm.get('msgText')?.value,
      loggedInUserName: this.storageService.username,
    });
    this.genCampaignName();
  }

  genCampaignName() {
    this.uploadCampaignForm.patchValue({
      campaignName: this.campaignService.genCampaignName()
    })
  }

  // Api Calls

  // getAllShortUrl() {
  //   this.reportService.listAllShortUrl().subscribe(data => {
  //     this.listOfShortUrl = data.data.shortUrlList;
  //   })
  // }

  getSenderIdsByMsgType(type: string = this.messageType, subType: string = 'service-implicit') {
    let sub = type === 'others' ? subType : '';
    this.templateService.getSenderIdByMsgType(type, sub).subscribe(data => {
      this.uploadCampaignForm.get('senderId')?.reset?.();
      this.listOfSenderIds = data.data.senderIdList;
    })
  }

  getTemplates(type: string, senderId: any) {
    let params = { "loggedInUserName": this.storageService.username, "messageSubType": (this.messageType=='others')?this.uploadCampaignForm.get('serviceSubType')?.value:'', "messageType": (this.messageType=='others')?'service':this.messageType, "senderId": senderId };
    this.campaignService.getTemplatesBySenderId(params).subscribe(data => {
      // delete.templateName.params
      this.listOfTemplates = data.data.contentTemplateList;
    })
  }

  // Model Events

  handleOk() {
    this.isOkLoading = true;
    this.submitUploadCampaign();
  }

  handleCancel() {
    this.isVisible = false;
  }

  // File Upload
  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };

  // Split Array

  get splitPart() {
    return this.uploadCampaignForm.controls["splitPart"] as FormArray;
  }

  splitArray(): FormGroup {
    return this.fb.group({
      id: '',
      from: '',
      to: '',
      hh: '',
      mm: '',
    })
  }

  addSplitArray() {
    this.splitPart.push(this.splitArray());
  }

  removeSplitArray(i: number) {
    this.splitPart.removeAt(i);
  }


  open(){
    this.visible = true;
  }
    
  
  close(){
    this.visible = false;
  }
  
  getActiveDomains() {
    this.reportService.getActiveDomain().subscribe((data: any) => {
      this.listActiveHost = data?.data.hostNameList;
    })
  }
  
  deleteShortUrl(data: any) {
    let params = { "loggedInUserName": "airteltesting", "shortUrlId": data.shortUrlId, "operation": "removeShortUrlFromList" };
    this.reportService.deleteShortUrl(params).subscribe(data => {
      this.getAllShortUrl();
      if (data.result === "Success") {
        this.utilService.showSuccessMsg(data.message);
      } else {
        this.utilService.showErrorMsg(data.message);
      }
    })
  }
  
  getAllShortUrl() {
    this.reportService.listAllShortUrl().subscribe(data => {
      this.listOfShortUrl = data.data.shortUrlList;
    })
  }
  
  submitshorturl() {
    let formData = this.viewshortUrl.getRawValue();
  
    if (formData.callbackUrlValue !== '') {
      formData.callbackUrl = formData.callbackUrl + this.viewshortUrl.get('callbackUrlValue')?.value;
    } else {
      formData.callbackUrl = "";
    }
  
    if (formData.longUrlValue !== "") {
      formData.longUrl = formData.longUrl + formData.longUrlValue;
    }
  
    delete formData.callbackUrlValue;
    delete formData.longUrlValue;
    delete formData.shortCode;
  
    this.reportService.addviewshortUrl(formData).subscribe(
      data => {
        if (data.result === "Success") {
          this.visible = false;
          this.utilService.showSuccessMsg(data.message);
          this.getAllShortUrl();
  
          // Automatically select the new short URL name
          if (data.data && data.data.shortUrlName) {
            this.viewshortUrl.patchValue({ shortUrlName: [data.data.shortUrlName] });
          }
  
          // Fetch all short URLs and log the new short URL name
          this.reportService.listAllShortUrl().subscribe(data => {
            // Store the list of short URLs
            this.listOfShortUrl = data.data.shortUrlList;
          
            // Find and log the newly created short URL by formData.shortUrlName
            const newShortUrl = this.listOfShortUrl.find(
              (url: any) => url.name === formData.shortUrlName
            );
            if (newShortUrl) {
              console.log('New Short URL Found:', newShortUrl);
            } else {
              console.log('No match found for the new short URL name:', formData.shortUrlName);
            }
          
            // Search for a specific name ("atulya2")
            const searchName = formData.name;
            const matchedItem = this.listOfShortUrl.find((item: { name: string }) => item.name === searchName);
            if (matchedItem) {
              console.log('Matched Item:', matchedItem);
              this.uploadCampaignForm.patchValue({
                shortUrlName: [matchedItem.name]
              });
              // Use matchedItem for further processing
            } else {
              console.log('No match found for the name:', searchName);
            }
          });
          
        } else {
          this.utilService.showErrorMsg(data.message);
          this.shortCode = data.data?.shortCode;
        }
      },
      err => {
        this.utilService.showErrorMsg('Something Went Wrong');
      }
    );
  }
  onShortUrlSelect(event: string[], field: any) {
    if (this.listOfURLSelected.length < event.length) {
      const newItem = event.filter((x: any) => !this.listOfURLSelected.includes(x))[0];
      console.log("newItem", newItem);
      this.listOfURLSelected = event;
      this.onItemSelect(newItem, field);
    } else {
      const removedItem = this.listOfURLSelected.filter((x: any) => !event.includes(x))[0];
      console.log("removedItem", removedItem);
      this.listOfURLSelected = this.listOfURLSelected.filter((x: any) => x !== removedItem);
      this.removeShortUrlInMsgText(removedItem);
    }
  
    // Handle the selection of the last item in the list (if any)
    const matchedItem = this.listOfShortUrl.find((item: { name: string }) => item.name === event[event.length - 1]);
    if (matchedItem) {
      console.log('Matched Item:', matchedItem);
  
      // Patch the form control with the selected short URL
      this.viewshortUrl.patchValue({
        shortUrlName: [matchedItem.name]
      });
    } else {
      console.log('No match found for the name:', event[event.length - 1]);
    }
  }

}

function setHours(arg0: Date, arg1: number) {
  throw new Error('Function not implemented.');
}
function subMinutes(arg0: Date, arg1: number) {
  throw new Error('Function not implemented.');
}

